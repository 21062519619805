<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
</div>
<div class="internal-content">
    <form class="form-control-submit" [formGroup]="userForm" (ngSubmit)="onSubmit()">
        


        <mat-form-field>
            <mat-label for="name">Nombre</mat-label>
            <input matInput type="text" class="form-control" id="name" formControlName="name">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="acronym">Acrónimo</mat-label>
            <input matInput type="text" class="form-control" id="acronym" formControlName="acronym">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="description">Descripción</mat-label>
            <input matInput type="text" class="form-control" id="description" formControlName="description">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="start_time">Hora de servicio</mat-label>
            <input matInput type="time" class="form-control" id="start_time" formControlName="start_time">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="end_time">Hora de cierre</mat-label>
            <input matInput type="time" class="form-control" id="end_time" formControlName="end_time">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="simultaneous_appointments">Citas simultáneas</mat-label>
            <input matInput type="number" class="form-control" id="simultaneous_appointments" formControlName="simultaneous_appointments">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="time_interval">Intervalo de tiempo (en minutos)</mat-label>
            <input matInput type="number" class="form-control" id="time_interval" formControlName="time_interval">
        </mat-form-field>

        <div class="button-content">
            <button type="submit" class="btn btn-secondary btn-medium">Editar</button>
            <button type="button" class="btn btn-secondary btn-medium btn-danger" (click)="onDelete()">Eliminar</button>
        </div>
    </form> 
</div>