<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
</div>
<div class="internal-content">
    <form class="form-control-submit" [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <mat-form-field>
            <mat-label for="firstName">Nombre</mat-label>
            <input matInput type="text" class="form-control" id="firstName" formControlName="firstName">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="lastName">Apellidos</mat-label>
            <input matInput type="text" class="form-control" id="lastName" formControlName="lastName">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="employeeNumber">Número de empleado</mat-label>
            <input matInput type="text" class="form-control" id="employeeNumber" formControlName="employeeNumber">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="employeeDNI">DNI</mat-label>
            <input matInput type="text" class="form-control" id="employeeDNI" formControlName="employeeDNI">
        </mat-form-field>
        
        <mat-form-field>
            <mat-label for="email">Email</mat-label>
            <input matInput type="text" class="form-control" id="email" formControlName="email">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="password">Contraseña</mat-label>
            <input matInput type="password" class="form-control" id="password" formControlName="password">
        </mat-form-field>
        <mat-form-field> 
            <mat-label for="assignedAppointmentType_id">Tipo de cita</mat-label>
            <mat-select multiple id="assignedAppointmentType_id" formControlName="assignedAppointmentType_id">
                <mat-option *ngFor="let appointmentType of appointmentTypes" [value]="appointmentType.id">{{appointmentType.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label for="overthecounter_id">Mesa</mat-label>
            <mat-select class="form-control" formControlName="overthecounter_id">
                <mat-option *ngFor="let overthecounter of overthecounters" [value]="overthecounter.id">{{overthecounter.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <mat-form-field>
            <mat-label for="now_appointment">Cita actual</mat-label>
            <input matInput type="text" class="form-control" id="now_appointment" formControlName="now_appointment">
        </mat-form-field> -->
        <div class="button-content">
            <button type="submit" class="btn btn-secondary btn-medium">Crear</button>
        </div>
    </form> 
</div>