<div class="appointment-main">
    <div class="appointment-box">
        <div class="appointment-header">
            <img src="{{logotipo}}">
        </div>
        <div class="appointment-form">
            <h3>Seleccione un departamento</h3>
            <div class="appointment-buttons">
                <button class="btn btn-primary btn-medium" *ngFor="let type of types" (click)="nextStep(type)">{{type.name}}</button>
            </div>
        </div>
        <div class="appointment-footer">
            <!-- <button class="btn btn-secondary" [routerLink]="['/appointment']">Inicio</button> -->
        </div>
    </div>
</div>