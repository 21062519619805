import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'GESTURCIT';

  // llama al enviroment para crear una variable de color con el primaryColor

  color = environment.primaryColor;
  color2 = environment.primaryColorVariation;

  // horizontal = environment.horizontal;

  
  constructor(

  ) { 

    // añade al css el color de primaryColor
    document.documentElement.style.setProperty('--primary-color', this.color);
    document.documentElement.style.setProperty('--primary-color-variation', this.color2);

    document.documentElement.style.setProperty('--primary-color', this.color);

    if (environment.cajero) {
      const style = document.createElement('style');
      style.innerHTML = `
      .appointment-main .appointment-box {
        min-height: calc(100vh - 100px);
      }
      `;
      document.head.appendChild(style);
    }

  }

}
