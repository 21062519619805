import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  page: any = {
    title: 'Editar usuario',
    subtitle: 'Aquí puedes editar un usuario'
  }

  id: any;

  userForm: FormGroup;
  appointmentTypes: any;
  overthecounters: any;
  userRole: any;

  loggedUser: any = JSON.parse(localStorage.getItem('user') || '{}');

  selectedValue: number = 0;
  roleValue: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private toastr: ToastrService
    ) {

      this.userForm = this.formBuilder.group({
        firstName: [''],
        lastName: [''],
        employeeNumber: [''],
        employeeDNI: [''],
        email: [''],
        password: [''],
        updatePassword: [''],
        role: [''],
        assignedAppointmentType_id: [''],
        overthecounter_id: [''],
        now_appointment: ['']
      });

     }

  ngOnInit(): void {
    this.getAppointmentTypes();
    this.getOverTheCounter();
    // obtener id de la pagina a traves del params del Router
    this.route.params.subscribe(params => {
      this.id = params['id']; // obtiene la ID desde los parámetros del router
    });
    this.data.getEmployeesDetails(this.id).subscribe((data: any) => {
      this.userRole = data.role;
      this.userForm.patchValue(data);

      console.log('data del usuario', data)
    });

    // updatePassword por defecto a 1
    this.userForm.get('updatePassword')?.setValue(0);
    this.userForm.get('password')?.disable();

    this.selectedValue = 0;

    // bloquear campo contraseña si updatePassword es 1
    this.userForm.get('updatePassword')?.valueChanges.subscribe((value) => {
      if (value == 1) {
        this.userForm.get('password')?.enable();
      } else {
        this.userForm.get('password')?.disable();
      }
    });
  }

  getAppointmentTypes() {
    this.data.getShiftTypes().subscribe((data: any) => {
      this.appointmentTypes = data;
    });
  }

  getOverTheCounter() {
    this.data.getOverthecounter().subscribe((data: any) => {
      this.overthecounters = data;
    });
  }

  onSubmit() {
    console.log('Formulario enviado', this.userForm.value);
    // enviar solo los datos que se han actualizado
    this.data.updateEmployee(this.userForm.value, this.id).subscribe(() => {

      this.data.getEmployeesDetails(this.id).subscribe((data: any) => {
        console.log('Usuario actualizado, data del usuario actualizado', data);
        
        // comprobar si el usuario que está actualizando es el logueado:
        if (this.loggedUser.id == data.id) {
          // si es el usuario logueado, actualizar el usuario en localStorage
          localStorage.setItem('user', JSON.stringify(data));
        }
        // localStorage.setItem('user', JSON.stringify(data));
      });
      const userLocal = JSON.parse(localStorage.getItem('user') || '{}');

      console.log('userLocal', userLocal);
      this.router.navigate(['/admin/shift']);
      this.toastr.success('Usuario actualizado con éxito', 'Usuario actualizado');
    });
    
  }

  deleteUser() {
    // borra el usuario tras confirmar si desea hacerlo
    if (confirm('¿Estás seguro de que deseas eliminar este usuario?')) {
      this.data.deleteEmployee(this.id).subscribe(() => {
        this.router.navigate(['/admin/users']);
        this.toastr.success('Usuario eliminado con éxito', 'Usuario eliminado');
      });
    }
    
  }

}
