<aside>
    <div class="sidebar">
        <div class="sidebar-header">
        </div>
        <ul class="list-unstyled components">
            <li>
                <a routerLink="/admin/dashboard" routerLinkActive="router-link-active">Dashboard</a>
            </li>
            <li>
                <a routerLink="/admin/shift" routerLinkActive="router-link-active">Lista de Turnos</a>
            </li>
            <li *ngIf="mode == 1">
                <a routerLink="/admin/appointment" routerLinkActive="router-link-active">Lista de Citas</a>
            </li>
            <li *ngIf="userRole == 'admin'">
                <a routerLink="/admin/users" routerLinkActive="router-link-active">Usuarios</a>
            </li>
            <li>
                <a routerLink="/admin/categories" routerLinkActive="router-link-active">Categorías</a>
            </li>
            <li>
                <a routerLink="/admin/overthecounter" routerLinkActive="router-link-active">Mesas</a>
            </li>
            <li>
                <a routerLink="/admin/marketing" routerLinkActive="router-link-active">Banners</a>
            </li>
            <li>
                <a routerLink="/admin/options" routerLinkActive="router-link-active">Configuración</a>
            </li>
        </ul>
    </div>
</aside>