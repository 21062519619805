<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
    <div class="button-group">
        <!-- <a class="btn btn-secondary" [routerLink]="['/admin/options/add']">Crear nueva opción</a> -->
    </div>
</div>
<div class="internal-content">
    <mat-form-field>
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Escribe para filtrar" #input>
      </mat-form-field>
      
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
        
        <!-- name_options Column -->
        <ng-container matColumnDef="name_options">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.name_options}} </td>
          </ng-container>

          <!-- name_options Column -->
          <ng-container matColumnDef="options_type">
              <th mat-header-cell *matHeaderCellDef> Slug </th>
              <td mat-cell *matCellDef="let element"> {{element.options_type}} </td>
            </ng-container>
      
        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element"> {{element.status ? 'Activo' : 'Deshabilitado'}} </td>
        </ng-container>
      
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element"> 
                <a class="btn btn-secondary btn-normal" [routerLink]="['/admin/options/edit/'+ element.id]">Editar</a>
            </td>
          </ng-container>
      
      
      
        <!-- Agrega más columnas según sea necesario -->
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
        <!-- Fila que se muestra cuando no hay datos coincidentes -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            No hay datos que coincidan con el filtro "{{input.value}}"
            </td>
        </tr>
      </table>

      <div class="no-availability-calendar" *ngIf="mode === 1">

        <h2 class="title">Calendario de no disponibilidad</h2>
        <div class="option-calendar-box">
          <div class="option-calendar-column">
            
            <div class="calendar">
              <div class="calendar-container">
                <ngb-datepicker [(ngModel)]="model" (dateSelect)="onDateSelect($event)" 
                [dayTemplate]="tDay" outsideDays="hidden"></ngb-datepicker>
              </div>
              
              <ng-template #tDay let-date let-currentMonth="currentMonth">
                <div [class.date-with-cita]="tieneCita(date)" 
                [class.disabled]="!tieneCita(date) || date.month !== currentMonth">
                {{ date.day }}
              </div>
            </ng-template>
            
          </div>
        </div>
        <div class="option-calendar-column categories">    
          <div class="categories-checkbox-list">
            <h3>Categorías</h3>
            <div>
              <mat-checkbox (change)="toggleAllSelection()" [checked]="allSelected">
                {{allSelected ? 'Deseleccionar todos' : 'Seleccionar todos'}}
              </mat-checkbox>
            </div>
            <div *ngFor="let category of types">
              <mat-checkbox [(ngModel)]="category.selected" (change)="onCategoryChange(category.id)">
                {{category?.name}}
              </mat-checkbox>
            </div>
          </div>
          
          <button class="btn btn-secondary" (click)="saveData()">Guardar cierre de día</button>
          
          
        </div>
      </div>
</div>