<div class="appointment-main">
    <div class="appointment-box">
        <div class="appointment-header">
            <img src="{{logotipo}}">
        </div>
        <div class="appointment-form">
            <h3>Validar cita</h3>
            <div class="appointment-input">
                <p>Puedes buscar por número de cita, nombre, correo o teléfono</p>
                <input type="text" placeholder="Código de cita, nombre, correo o teléfono" maxlength="50" [(ngModel)]="code" (input)="filterResults()" autocomplete="off" autocorrect="off" >
            </div>
            <div class="appointment-result">
                <div *ngFor="let appointment of filteredResults" class="appointment-result-box">
                    <div class="result-content" (click)="confirm(appointment)" *ngIf="this.code?.length >= 6">
                        <p class="identifier-appointment"><strong>{{appointment?.appointmentIdentifier}}</strong></p>
                        <p>{{appointment?.firstName}} {{appointment?.lastNameSplit}}</p>
                        <p>{{appointment?.datetime | date:'dd-MM-yyyy HH:mm'}}h</p>
                        <p><strong>{{appointment?.type.name}}</strong></p>
                        <button class="btn btn-primary bnt-small">Seleccionar</button>
                    </div>
                </div>
                <div class="appointment-result-box no-result" *ngIf="this.code?.length > 3 && filteredResults.length === 0">
                    <h4>No se encontraron resultados</h4>
                    <p>Prueba a escribir otro dato como tu nombre, tu dni, tu correo electrónico o tu móvil</p>
                    <p>¿Seguro que era hoy la cita?</p>
                </div>
                <div class="appointment-result-box no-result" *ngIf="this.code?.length >= 4 && this.code?.length < 6 && filteredResults.length > 4">
                    <p><b><h4>Hay demasiados resultados</h4></b></p>
                    <p>Prueba a escribir otro dato más específico como tu nombre, tu dni, tu correo electrónico o tu móvil</p>
                    <p>¿Seguro que era hoy la cita?</p>
                </div>
            </div>
        </div>
        <div class="appointment-footer">
            <!-- <button class="btn btn-secondary" [routerLink]="['/shift']">Volver a selección</button> -->
        </div>
    </div>
</div>