import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  options: any = [];

  logotipo: any;

  user = {
    firstName: 'Admin',
    lastName: 'Adminddd',
    email: ''
  }

  constructor(private data: DataService) { }

  ngOnInit(): void {

    this.data.getLogguedUser().subscribe((res: any) => {
      this.user = res;
    });

    this.logotipo = environment.logotipo;
  }

  logout() {
    this.data.logout();
  }


  

}
