import {Component, ViewChild, OnInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css']
})

export class AppointmentListComponent implements OnInit {
  @ViewChild('input') input: any;
  @ViewChild('startDate') startDateField: any;
  @ViewChild('endDate') endDateField: any;
  @ViewChild('categorySelect') categorySelect: any;

  page = {
    title: 'Lista de citas',
    subtitle: 'Aquí puedes ver la lista de citas registradas en el sistema'
  }

  allAppointments: any = [];
  appointments: any = [];

  appointmentFuture: any = [];

  displayedColumns: string[] = [
    'appointmentIdentifier', 
    'firstName', 
    'lastName', 
    'dni', 
    'phone', 
    'email', 
    'datetime', 
    'typeName',
    'status',
    'actions'
  ];
  dataSource = new MatTableDataSource(this.appointments);


  startDate: Date | null = null;
  endDate: Date | null = null;

  loading: boolean = true;

  total: number = 0;
  perPage: number = 0;
  currentPage: number = 1;


  constructor(private data: DataService, private route: Router) { 
  }


  ngOnInit(): void {
    this.getAppointments();
    this.getCategories();
  }

  getAppointments() {
    this.data.getAllAppointments(this.currentPage).subscribe((res: any) => {
      this.appointments = res.data;
      this.allAppointments = res.data;
      this.total = res.total;
      this.perPage = res.per_page;
      this.currentPage = res.current_page;
      // ordena los objetos por fecha y hora basado en datetime
      this.allAppointments.sort((a: any, b: any) => {
        return new Date(a.datetime).getTime() - new Date(b.datetime).getTime();
      });

      // filtra los array de objetos por fecha y hora desde hoy hasta el más lejano, eliminando los anteriores al día de hoy
      this.appointmentFuture = this.appointments.filter((appointment: any) => {
        return new Date(appointment.datetime).getTime() >= new Date().getTime();
      });

      // ordena los objetos por fecha y hora basado en datetime
      this.appointmentFuture.sort((a: any, b: any) => {
        return new Date(a.datetime).getTime() - new Date(b.datetime).getTime();
      });
    
      console.log(this.appointments);
      this.dataSource.data = this.appointmentFuture;
      this.loading = false;
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyDateFilter(event: any, type: string) {
    const selectedDate = event.value;

    if (type === 'start') {
      this.startDate = selectedDate;
    } else if (type === 'end') {
      this.endDate = selectedDate;
    }

    this.filterAppointmentsByDate();
  }
  filterAppointmentsByDate() {
    if (this.startDate && this.endDate) {
      const start = new Date(this.startDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(this.endDate);
      end.setHours(23, 59, 59, 999);

      this.dataSource.data = this.appointmentFuture.filter((appointment: any) => {
        const appointmentDate = new Date(appointment.datetime);
        return appointmentDate >= start && appointmentDate <= end;
      });
    } else if (this.startDate) {
      const start = new Date(this.startDate);
      start.setHours(0, 0, 0, 0);

      this.dataSource.data = this.appointmentFuture.filter((appointment: any) => {
        const appointmentDate = new Date(appointment.datetime);
        return appointmentDate >= start;
      });
    } else if (this.endDate) {
      const end = new Date(this.endDate);
      end.setHours(23, 59, 59, 999);

      this.dataSource.data = this.appointmentFuture.filter((appointment: any) => {
        const appointmentDate = new Date(appointment.datetime);
        return appointmentDate <= end;
      });
    } else {
      this.dataSource.data = this.appointmentFuture;
    }
  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  categories: any[] = [];


  getCategories() {
    this.data.getShiftTypes().subscribe((res: any) => {
      this.categories = res;
    });
  }
  applyCategoryFilter(event: any) {
    const selectedCategoryName = event.value || 'ALL';


    if (selectedCategoryName === 'ALL') {
      this.dataSource.data = this.allAppointments;
    } else {
      this.dataSource.data = this.allAppointments.filter((appointment: any) => {
        console.log(appointment.type.id);
        return appointment.type.id === selectedCategoryName;
      });
    }
  }

  applyTodayFilter() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);

    this.dataSource.data = this.appointmentFuture.filter((appointment: any) => {
      const appointmentDate = new Date(appointment.datetime);
      return appointmentDate >= today && appointmentDate <= endOfToday;
    });
  }

  applyTomorrowFilter() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    const endOfTomorrow = new Date();
    endOfTomorrow.setDate(endOfTomorrow.getDate() + 1);
    endOfTomorrow.setHours(23, 59, 59, 999);

    this.dataSource.data = this.appointmentFuture.filter((appointment: any) => {
      const appointmentDate = new Date(appointment.datetime);
      return appointmentDate >= tomorrow && appointmentDate <= endOfTomorrow;
    });
  }

  clearFilters() {
    this.getAppointments();
    this.input.nativeElement.value = '';
    this.startDateField.nativeElement.value = '';
    this.endDateField.nativeElement.value = '';
    this.categorySelect.value = null;

    // Llama a las funciones de filtrado para aplicar los cambios
    const customEvent = { target: { value: '' } } as unknown as Event;
    this.applyFilter(customEvent);
    this.applyDateFilter({ target: this.startDateField.nativeElement }, 'start');
    this.applyDateFilter({ target: this.endDateField.nativeElement }, 'end');
    this.applyCategoryFilter({ value: null });

  }

  edit(id: any) {
    console.log('Edit', id);
    this.route.navigate(['/admin/appointment/details/', id.id]);
  }

  seeAll() {
    this.getAllAppointments();
  }

  getAllAppointments() {
    this.data.getAllAppointments(this.currentPage).subscribe((res: any) => {
      this.appointments = res.data;
      this.total = res.total;
      this.perPage = res.per_page;
      this.currentPage = res.current_page;
      this.dataSource.data = this.appointments;
      
    });
  }

  onPageChange(event: any) {
    this.currentPage = event.pageIndex + 1;
    this.getAllAppointments();
  }

}

