import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-categories-edit',
  templateUrl: './categories-edit.component.html',
  styleUrls: ['./categories-edit.component.css']
})
export class CategoriesEditComponent implements OnInit {

  page: any = {
    title: 'Editar categoría',
    subtitle: 'Aquí puedes editar una categoría'
  }

  userForm: FormGroup;
  appointmentTypes: any;
  overthecounters: any;
  id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private toastr: ToastrService
    ) {

      this.userForm = this.formBuilder.group({
        acronym: [''],
        name: [''],
        description: [''],
        start_time: [''],
        end_time: [''],
        simultaneous_appointments: [0],
        time_interval: [0]
      });
     }

  ngOnInit(): void {

    // obtener id de la pagina a traves del params del Router
    this.route.params.subscribe(params => {
      this.id = params['id']; // obtiene la ID desde los parámetros del router
    });
    this.data.getTypesDetails(this.id).subscribe((data: any) => {
      console.log('data', data)
      this.userForm.patchValue(data);
    });


  }

  onSubmit() {
    this.data.updateShiftType(this.userForm.value, this.id).subscribe(() => {
      this.router.navigate(['/admin/categories']);
      this.toastr.success('Categoría actualizada con éxito', 'Categoría actualizada');
    });
  }

  onDelete() {
    // confirmar borrado
    if (confirm('¿Estás seguro de que quieres eliminar esta categoría?')) {
      this.data.deleteShiftType(this.id).subscribe(() => {
        this.router.navigate(['/admin/categories']);
        this.toastr.success('Categoría eliminada con éxito', 'Categoría eliminada');
      });    
    }
  }
}
