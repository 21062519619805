<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
    <div class="button-group">
        <a class="btn btn-secondary" [routerLink]="['/admin/categories/add']">Crear nuevo</a>
    </div>
</div>
<div class="internal-content">
    <mat-form-field>
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Escribe para filtrar" #input>
      </mat-form-field>
      
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

        <!-- acronym Column -->
        <ng-container matColumnDef="acronym">
        <th mat-header-cell *matHeaderCellDef> Acrónimico </th>
        <td mat-cell *matCellDef="let element"> {{element.acronym}} </td>
        </ng-container>
        
        <!-- name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Categoría </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
      
        <!-- description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Descripción </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="start_time">
            <th mat-header-cell *matHeaderCellDef> Hora de servicio </th>
            <td mat-cell *matCellDef="let element"> {{element.start_time}} - {{element.end_time}} </td>
          </ng-container>

          <ng-container matColumnDef="simultaneous_appointments">
            <th mat-header-cell *matHeaderCellDef> Citas simultáneas </th>
            <td mat-cell *matCellDef="let element"> {{element.simultaneous_appointments}} </td>
          </ng-container>

          <ng-container matColumnDef="time_interval">
            <th mat-header-cell *matHeaderCellDef> Intervalo de tiempo </th>
            <td mat-cell *matCellDef="let element"> {{element.time_interval}} min</td>
          </ng-container>
      
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element"> 
                <a class="btn btn-secondary btn-normal" [routerLink]="['/admin/categories/edit/'+ element.id]">Editar</a>
            </td>
          </ng-container>
      
      
      
        <!-- Agrega más columnas según sea necesario -->
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
        <!-- Fila que se muestra cuando no hay datos coincidentes -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            No hay datos que coincidan con el filtro "{{input.value}}"
            </td>
        </tr>
      </table>
</div>