import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CategoryComponent } from './pages/shift/category/category.component';
import { ErrorComponent } from './pages/shift/error/error.component';
import { FormComponent } from './pages/shift/form/form.component';
import { MaintenanceComponent } from './pages/shift/maintenance/maintenance.component';
import { SuccessComponent } from './pages/shift/success/success.component';
import { ShiftDetailsComponent } from './pages/admin/shift-details/shift-details.component';
import { CategoriesComponent } from './pages/admin/categories/categories.component';
import { MarketingListComponent } from './pages/admin/marketing-list/marketing-list.component';
import { MarketingCreateComponent } from './pages/admin/marketing-create/marketing-create.component';
import { MarketingEditComponent } from './pages/admin/marketing-edit/marketing-edit.component';
import { MarketingDetailsComponent } from './pages/admin/marketing-details/marketing-details.component';
import { ShiftComponent } from './pages/shift/shift.component';
import { MonitorComponent } from './pages/monitor/monitor.component';

import { DatePipe } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import { LoginComponent } from './pages/admin/login/login.component';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';
import { AdminComponent } from './pages/admin/admin.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';

import { MatTableModule } from '@angular/material/table';
import { UserListComponent } from './pages/admin/user-list/user-list.component';
import { ShiftListComponent } from './pages/admin/shift-list/shift-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UserCreateComponent } from './pages/admin/user-create/user-create.component';
import { CategoriesEditComponent } from './pages/admin/categories-edit/categories-edit.component';
import { UserEditComponent } from './pages/admin/user-edit/user-edit.component';
import { MatNativeDateModule, MatPseudoCheckbox, MatPseudoCheckboxModule } from '@angular/material/core';
import { CategoriesCreateComponent } from './pages/admin/categories-create/categories-create.component';
import { OverthecounterListComponent } from './pages/admin/overthecounter-list/overthecounter-list.component';
import { OverthecounterEditComponent } from './pages/admin/overthecounter-edit/overthecounter-edit.component';
import { OverthecounterCreateComponent } from './pages/admin/overthecounter-create/overthecounter-create.component';
import { OptionsListComponent } from './pages/admin/options-list/options-list.component';
import { OptionsCreateComponent } from './pages/admin/options-create/options-create.component';
import { OptionsEditComponent } from './pages/admin/options-edit/options-edit.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { CreateAppointmentComponent } from './pages/appointment/create-appointment/create-appointment.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { registerLocaleData } from '@angular/common';

import localeEs from '@angular/common/locales/es';
import { AppointmentListComponent } from './pages/admin/appointment-list/appointment-list.component';
import { AppointmentDetailsComponent } from './pages/admin/appointment-detail/appointment-details.component';
import { ValidateComponent } from './pages/shift/validate/validate.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    AppComponent,
    CategoryComponent,
    ErrorComponent,
    FormComponent,
    MaintenanceComponent,
    SuccessComponent,
    ShiftDetailsComponent,
    CategoriesComponent,
    CategoriesEditComponent,
    MarketingListComponent,
    MarketingCreateComponent,
    MarketingEditComponent,
    MarketingDetailsComponent,
    ShiftComponent,
    AppointmentComponent,
    CreateAppointmentComponent,
    MonitorComponent,
    AdminHeaderComponent,
    LoginComponent,
    AdminSidebarComponent,
    AdminComponent,
    UserListComponent,
    MarketingListComponent,
    ShiftListComponent,
    CategoriesComponent,
    UserListComponent,
    UserCreateComponent,
    UserEditComponent,
    CategoriesCreateComponent,
    OverthecounterListComponent,
    OverthecounterEditComponent,
    OverthecounterCreateComponent,
    OptionsListComponent,
    OptionsCreateComponent,
    OptionsEditComponent,
    AppointmentListComponent,
    AppointmentDetailsComponent,
    ValidateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SwiperModule,
    NoopAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule, 
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    NgbModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent] 
})
export class AppModule { }
registerLocaleData(localeEs);
