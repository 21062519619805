<main [class.horizontal]="horizontal">
  <iframe src="../../../assets/notification_3.mp3" allow="autoplay" id="audio"></iframe>
  <audio id="notification" src="../../../assets/notification_2.mp3" autoplay></audio>
  <div class="content-general">
    <header>
      <div class="title">
        <img src="{{logotipo}}">
        <h2>Sistema de citas <span class="version">v.0.1.202305</span></h2>
      </div>

    <div class="marketing-box">
      <div class="marketing-list">

        <swiper
        [config]="configMarketing"
      >
      
        <ng-template swiperSlide *ngFor="let banner of banners">
          <!-- <img src="{{storageURL}}{{banner.imagen}}"> -->

          <div class="banner-image">
            <img src="{{storageURL}}{{banner.imagen}}">
            <img src="{{storageURL}}{{banner.imagen}}" class="background-filter">
        </div>
        </ng-template>
      </swiper>
        
      </div>
    </div>
      <div class="data-box">
        <div class="date">
          {{ todayDate | date: 'dd/MM/yyyy'}}
        </div>
        <div class="hour">
          {{ time | date: 'HH:mm:ss'}}h
        </div>
      </div>
    </header>
    <div class="appointment-boxes">

    
    <div class="appointment-box">

      <div class="upcoming ap-box">
        <h3>Próximas citas</h3>
        <swiper
        [config]="config"
      >
      
        <ng-template swiperSlide *ngFor="let group of upcomingGroup">
            <ul class="appointments-list">
              <li class="appointment-item" *ngFor="let item of group">
                <span class="id">{{ item.shiftIdentifier }}</span>
                <div class="footer-card">
                  <span class="date">{{ item.created_at | date: 'HH:mm' }}h</span>
                </div>
              </li>
            </ul>
        </ng-template>
      </swiper>
      <div class="swiper-dots"></div>
      </div>
    </div>
    <div class="appointment-box">
      <div class="rightnow ap-box">
        <h3>Puede pasar</h3>
        <ul class="appointments-list">
          <li class="appointment-item" *ngFor="let item of rightnow">
            <span class="id">{{item.shiftIdentifier}}</span>
            <div class="footer-card">
              <span class="staff">{{item.employee?.over_the_counter?.name}}</span>
              <span class="date"> {{item.updated_at | date: 'HH:mm'}}h </span>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
    <div class="marketing-box">
      <div class="marketing-list">

        <swiper
        [config]="configMarketing"
      >
      
        <ng-template swiperSlide *ngFor="let banner of banners">
          <!-- <img src="{{storageURL}}{{banner.imagen}}"> -->

          <div class="banner-image">
            <img src="{{storageURL}}{{banner.imagen}}">
            <img src="{{storageURL}}{{banner.imagen}}" class="background-filter">
        </div>
        </ng-template>
      </swiper>
        
      </div>
    </div>
  </div>
</main>
