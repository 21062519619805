<header>
    <div class="logo">
        <img src="{{logotipo}}">
    </div>
    <div class="title">
        <div class="username">
            <span>{{user?.firstName}} {{user?.lastName}}</span>
        </div>
        <div class="logout">
            <a class="btn btn-secondary" (click)="logout()">Cerrar sesión</a>
        </div>
    </div>
</header>