<div class="appointment-main">
    <div class="appointment-box">
        <div class="appointment-header">
            <img src="{{logotipo}}">
        </div>
        <div class="appointment-form" *ngIf="this.configuration.mode == 2">
            <button class="btn btn-primary btn-big" (click)="nextStep('next')">Solicitar turno</button>
        </div>
        <div class="appointment-form" *ngIf="this.configuration?.mode == 1">
            <button class="btn btn-primary btn-big" (click)="nextStep('validate')">Validar cita</button>
            <button class="btn btn-primary btn-big" (click)="nextStep('next')">No tengo cita</button>
        </div>
        <div class="appointment-footer" *ngIf="this.configuration?.banners == true">
            <swiper
            [config]="config"
            (slideChange)="onSlideChange()"
          >
          <ng-template swiperSlide *ngFor="let banner of banners">
            <div class="banner-image">
                <img src="{{storageURL}}{{banner.imagen}}">
                <img src="{{storageURL}}{{banner.imagen}}" class="background-filter">
            </div>
          </ng-template>
          </swiper>
        </div>   
        <div class="appointment-transparent" *ngIf="this.configuration?.banners == false">

        </div>
    </div>
</div>