import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.css']
})
export class AppointmentDetailsComponent implements OnInit {

  page: any = {
    title: 'Detalles de la cita',
    subtitle: 'Aquí puedes ver los detalles de la cita seleccionada'
  }
  appointment: any;

  appoinmentId: any;
  appointmentForm!: FormGroup;

  shift: any;

  types: any;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private data: DataService, 
    private fb: FormBuilder, 
    private toastr: ToastrService
  ) { }

  
  ngOnInit(): void {

    this.getAppointmentTypes();
    this.appointmentForm = this.fb.group({
      appointmentIdentifier: [{ value: '', disabled: true }],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      dni: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      appointmentType_id: ['', Validators.required],
      datetime: ['', Validators.required]
    });

    this.route.paramMap.subscribe(params => {
      const appointmentId = params.get('id');
      this.appoinmentId = appointmentId;
      if (appointmentId) {
        this.data.getAppointmentDetails(appointmentId).subscribe(appointment => {
          this.appointment = appointment;
          if (this.appointmentForm) {
            this.appointmentForm.patchValue(appointment);

            if (this.appointment.shift_id) {
              this.getShift(this.appointment.shift_id);
            }
          }
        });
      }
    });
  }

  updateAppointment() {
    const updatedAppointment = this.appointmentForm.getRawValue();
    if ((updatedAppointment.datetime.match(/:/g) || []).length < 2) {
      updatedAppointment.datetime = updatedAppointment.datetime.replace('T', ' ') + ':00';
    } else {
      updatedAppointment.datetime = updatedAppointment.datetime.replace('T', ' ');
    }
    console.log('update appointment', updatedAppointment);

    this.data.updateAppointment(updatedAppointment, this.appointment.id).subscribe(
      appointment => {
        console.log(appointment);
        this.toastr.success('Cita actualizada correctamente', 'Cita actualizada');
        this.router.navigate(['/admin/appointment']);
      },
      error => {
        console.error(error);
        this.toastr.error('Hubo un error al actualizar la cita', 'Error');
      }
    );
  }

  getAppointmentTypes() {
    this.data.getShiftTypes().subscribe((res: any) => {
      this.types = res;
    });
  }


  getShift(id: any) {
    this.data.getShiftDetails(id).subscribe((res: any) => {
      this.shift = res;
      console.log('Shift', res);
    });
  }

}
