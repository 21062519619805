<div class="admin-login">
    <div class="login-header">
        <img src="{{logotipo}}">
        <h2>Administración</h2>
    </div>
    <div class="login-box">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="username">Usuario</label>
                <input type="text" formControlName="email" placeholder="email">
            </div>
            <div class="form-group">
                <label for="password">Contraseña</label>
                <input type="password" formControlName="password" placeholder="Contraseña">
            </div>
            <button type="submit" class="btn btn-secondary btn-small">Iniciar sesión</button>
        </form>
    </div>
</div>