import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {
  generalOptions: any = [];

  logotipo: any;

  constructor(
    private data: DataService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.checkStatus();
    this.logotipo = environment.logotipo;

    setInterval(() => {
      this.checkStatus();
    }, 10000);
  }

  checkStatus() {
    this.data.getOptions().subscribe((res: any) => {
      this.generalOptions = res;

      const statusMaintenance = this.generalOptions.find((item: any) => item.options_type == 'mantenimiento');
      if(statusMaintenance && statusMaintenance.status == '0') {
        this.router.navigate(['/appointment']);
      } 
    });
  }

}
