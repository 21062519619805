import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-overthecounter-create',
  templateUrl: './overthecounter-create.component.html',
  styleUrls: ['./overthecounter-create.component.css']
})
export class OverthecounterCreateComponent implements OnInit {

  page: any = {
    title: 'Crear mesa',
    subtitle: 'Aquí puedes crear una nueva mesa'
  }

  userForm: FormGroup;
  id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private toastr: ToastrService
    ) {

      this.userForm = this.formBuilder.group({
        name: [''],
        description: ['']
      });
      
     }

  ngOnInit(): void {
  }

  onSubmit() {
    this.data.createOverthecounter(this.userForm.value).subscribe(() => {
      this.router.navigate(['/admin/overthecounter']);
      this.toastr.success('Mesa creada con éxito', 'Mesa creada');
    }, (error) => {
      this.toastr.error('Error al crear la mesa', 'Error');
    });
  }
  
}
