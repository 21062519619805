import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-options-create',
  templateUrl: './options-create.component.html',
  styleUrls: ['./options-create.component.css']
})
export class OptionsCreateComponent implements OnInit {

  file: any;

  constructor(private data: DataService) { }

  ngOnInit(): void {
  }


  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  // añadido componente de creación de opciones
  createOption() {

    // recoge los datos del formulario
    const optionName = (document.getElementById('optionName') as HTMLInputElement).value;
    const optionType = (document.getElementById('optionType') as HTMLTextAreaElement).value;

    if (!optionName || !optionType) {
      console.error('Los campos nombre y tipo son obligatorios');
      return;
    }
    console.log(optionName, optionType);

    const data: any = {
      name_options: optionName,
      option_type: optionType,
      status: 1
    };

    console.log(data.name_option, data.option_type, data.logo);

    this.data.createOption(data, this.file).subscribe((res: any) => {
      console.log(res);
    });
  }


}
