import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  data: any;
  date = new Date();
  logotipo: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log('Success');
    this.logotipo = environment.logotipo;

    // Recuperar los datos del estado de navegación
    const state = history.state;
    if (state && state.res) {
      this.data = state.res;
      console.log('Datos recuperados:', this.data);

      // Si hay datos, imprimir la pantalla con print()
      if (this.data) {
      // imprimir la fecha y hora actual en formato legible
      this.date = new Date();
      this.date.toLocaleString();
      console.log('Fecha y hora actual:', this.date);

      if (environment.pdfPrint) {
        // Generar un PDF con los datos y descargarlo
        const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [80, 80] // 80mm width, 297mm height (A4 height)
        });

        const pageWidth = doc.internal.pageSize.getWidth();

        doc.setFontSize(20);
        doc.setFont('helvetica', 'normal');
        const titleText = 'SU TURNO';
        const titleX = (pageWidth - doc.getTextWidth(titleText)) / 2;
        doc.text(titleText, titleX, 20);

        doc.setFontSize(30);
        doc.setFont('helvetica', 'bold');
        const shiftText = `${this.data.cita.shiftIdentifier}`;
        const shiftX = (pageWidth - doc.getTextWidth(shiftText)) / 2;
        doc.text(shiftText, shiftX, 40);

        doc.setFontSize(16);
        doc.setFont('helvetica', 'normal');
        const minutes = this.date.getMinutes().toString().padStart(2, '0');
        const dateText = `${this.date.toLocaleDateString()} ${this.date.getHours()}:${minutes}h`;
        const dateX = (pageWidth - doc.getTextWidth(dateText)) / 2;
        doc.text(dateText, dateX, 60);

        // Descargar el PDF
        doc.save('turno.pdf');
      } else {
        // imprimir la pantalla
        setTimeout(() => {
          window.print();
        }, 1000);
        
      }

      setTimeout(() => {
        this.router.navigate(['/']);
      }, environment.timeToReload);
      }
    }

    // Si no hay datos, redirigir al inicio
    if (!this.data) {
      this.router.navigate(['/']);
    }


  }

}
