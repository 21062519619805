import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  logotipo: any;

  constructor(private data: DataService, private formBuilder: FormBuilder) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
   }

  ngOnInit(): void {

    this.logotipo = environment.logotipo;
  }

  onSubmit() {
    console.log('Form submitted', this.loginForm);
    // recoge los datos de loginForm
    if (this.loginForm.valid) {
      this.data.login(this.loginForm.value);
    }
    // envia los datos al servicio
   // this.data.login(this.loginForm.value);

  }

}
