<audio id="notification" src="../../../assets/notification_4.mp3" autoplay></audio>
<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
</div>
<div class="internal-content">

    <div class="right-now-appointment">
        <div class="appointment-box">
            <div class="appointment-info">
              <h3>Citas pendientes en total ahora: <b>{{pendingAppointments?.length}}</b></h3>
              <h3>Citas pendientes de tus categorías: <b>{{myAppointments?.length}}</b></h3>
              <h3>Actualmente en: <b>{{loggedUser?.over_the_counter.name}}</b></h3>
                <h3>Categorías asignadas:</h3>
                <ul>
                  <li  *ngFor="let item of categories">
                    <span>{{item.name}} - <b>{{item.acronym}}</b></span>
                  </li>
                </ul>
                <p><a class="btn btn-secondary btn-normal" (click)="reload()">Recargar</a></p>
                <p><a class="btn btn-secondary btn-normal" [routerLink]="['/admin/users/edit/'+ loggedUser?.id]">Editar mi usuario - Mesa y categorías</a></p>
            </div>
            <div class="appointment-info" *ngIf="rightNowAppointment != null">
                <h3>Cita actual</h3>
                <p class="ap-info"> <span class="identifier">{{rightNowAppointment?.shiftIdentifier}}</span> {{rightNowAppointment?.employee?.over_the_counter?.name}}</p>
                <p>{{rightNowAppointment?.created_at | date: 'dd/MM/yyy HH:mm'}}h</p>
                <p>{{rightNowAppointment?.type?.name}}</p>
                <p>{{rightNowAppointment?.state?.name}} </p>
            </div>
            <div class="appointment-info" *ngIf="rightNowAppointment === null">
                <h3>Cita actual</h3>
                <p class="ap-info"> <span class="identifier">--</span> No hay cita asignada</p>
            </div>
            <div class="appointment-info buttons" [ngClass]="{disabled_buttons: rightNowAppointment == null}">
                <a class="btn btn-secondary not-response" (click)="cancelAppointment(rightNowAppointment.id)">No responde</a>
                <a class="btn btn-secondary finish" (click)="finishAppointment(rightNowAppointment.id)">Finalizar</a>
                <a class="btn btn-secondary next" (click)="nextAppointment(rightNowAppointment.id)">Siguiente</a>
                <a class="btn btn-secondary reasign" (click)="reasignAppointment(rightNowAppointment.id)">Pendiente</a>

            </div>
        </div>
    </div>
    <div class="filter-box">
      <mat-form-field>
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Escribe para filtrar" #input>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Fecha</mat-label>
        <input matInput type="date" placeholder="Choose a date" (change)="applyFilterDate($event)">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Selecciona una opción</mat-label>
        <mat-select (selectionChange)="filterByCategory($event.value)" [(value)]="selectedValue">
          <input class="input-search" matInput placeholder="Buscar" [formControl]="searchControl">
          <mat-option *ngFor="let item of filteredOptions" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <a class="btn btn-secondary btn-normal" (click)="resetFilters()">Resetear filtros</a>
    </div>
      <div class="table-tabs">
        <a class="tab tab-primary" [ngClass]="{'selected': filterStatusIdMyAppointments === 1}" (click)="filterByStatusMyAppointments(1)">Mis citas pendientes</a>
        <a class="tab tab-primary" [ngClass]="{'selected': filterStatusIdAllAppointments === 1}" (click)="filterByStatus(1)">Todas las citas pendientes hoy</a>
        <a class="tab tab-primary" [ngClass]="{'selected': filterStatusId === 2}" (click)="filterByStatus(2)">Citas en curso</a>
        <a class="tab tab-primary" [ngClass]="{'selected': filterStatusId === 3}" (click)="filterByStatus(3)">Citas anuladas</a>
        <a class="tab tab-primary" [ngClass]="{'selected': filterStatusId === 4}" (click)="filterByStatus(4)">Citas completadas hoy</a>
        <a class="tab tab-primary" [ngClass]="{'selected': filterStatusId === 99}" (click)="filterByStatus(99)">Histórico de citas</a>
      </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
          
        <!-- id Column -->
        <ng-container matColumnDef="shiftIdentifier">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.shiftIdentifier}} </td>
        </ng-container>
        
        <!-- id Column -->
        <ng-container matColumnDef="employee">
            <th mat-header-cell *matHeaderCellDef> Asignado a </th>
            <td mat-cell *matCellDef="let element"> {{element.employee?.firstName}} {{element.employee?.lastName}} - {{element.employee?.over_the_counter?.name}}  </td>
        </ng-container>
    
        <!-- firstName Column -->
        <!-- <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}} </td>
        </ng-container> -->
    
        <!-- firstName Column -->
        <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef> Fecha </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd/MM/yyy HH:mm'}}h </td>
        </ng-container>
        
        <!-- appointmentType_id Column -->
        <ng-container matColumnDef="appointmentType_id">
            <th mat-header-cell *matHeaderCellDef> Categoría </th>
            <td mat-cell *matCellDef="let element"> {{element.type?.name}} </td>
          </ng-container>
      
        <!-- email Column -->
        <!-- <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container> -->

          <!-- phone Column -->
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Teléfono </th>
            <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
          </ng-container>

          <!-- status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element"> {{element.state.name}} </td>
          </ng-container>
      
        <!-- employeeDNI Column -->
        <ng-container matColumnDef="employeeDNI">
          <th mat-header-cell *matHeaderCellDef>DNI </th>
          <td mat-cell *matCellDef="let element"> {{element.dni}} </td>
        </ng-container>
      
        <!-- employeeNumber Column -->
        <ng-container matColumnDef="employeeNumber">
            <th mat-header-cell *matHeaderCellDef> Identificación </th>
            <td mat-cell *matCellDef="let element"> {{element.employeeNumber}} </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element" class="action-buttons">
              <!-- <a class="btn btn-secondary btn-normal" [routerLink]="['/admin/appointment/details/'+ element.id]">Ver detalles</a> -->
              <a class="btn btn-secondary btn-normal" (click)="assingAppointment(element.id)">Asignarme cita</a>
              <a class="btn btn-secondary btn-normal" *ngIf="element.status_id != 1" (click)="reasignAppointment(element.id)">Poner en PENDIENTE</a>
            </td>
          </ng-container>
      
      
      
        <!-- Agrega más columnas según sea necesario -->
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
        <!-- Fila que se muestra cuando no hay datos coincidentes -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            No hay datos que coincidan con el filtro "{{input.value}}"
            </td>
        </tr>
      </table>
      <!-- Paginador -->
      <mat-paginator [length]="total" [pageSize]="perPage" (page)="onPageChange($event)">
      </mat-paginator>

      <div class="loading loadpage" *ngIf="loading">
        <div class="loading-content">
            <div class="loading-icon">
                <img src="assets/oval.svg" alt="Cargando">
            </div>
        </div>
    </div>
</div>