import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.css']
})
export class ValidateComponent implements OnInit {

  appointments: any;
  res: any;
  code: any;

  logotipo: any;

  filteredResults: any[] = [];

  constructor(
    private router: Router,
    private data: DataService
  ) { }

  ngOnInit(): void {
    this.getAppointments();
    this.filteredResults = this.appointments;
    this.logotipo = environment.logotipo;

  }

  getAppointments() {
    console.log('Get appointments');
    this.data.getAppointments().subscribe((res: any) => {
      console.log(res);

      // res filtra para que solo aparezcan las citas de hoy desde las 00:00 hasta las 23:59
      const today = new Date();
      const start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
      const end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

      res = res.filter((item: any) => {
        const appointmentDate = new Date(item.datetime);
        return appointmentDate >= start && appointmentDate <= end && item.status_id === 1;
      });

      this.appointments = res;

      console.log('Appointments', this.appointments);
    });
  }

  // busqueda de code en appointment
  searchCode(code: any) {
    console.log('Search code', code);
    // buscar entre los appointments
    
  }

  filterResults(): void {
    const searchTerm = this.code ? this.code.toLowerCase() : '';

    if (searchTerm.length < 3) {
      this.filteredResults = [];
      return;
    }

    this.filteredResults = this.appointments.filter((item: any) =>
      (item.firstName?.toLowerCase().includes(searchTerm) || '') ||
      (item.lastName?.toLowerCase().includes(searchTerm) || '') ||
      (item.email?.toLowerCase().includes(searchTerm) || '') ||
      (item.dni?.toLowerCase().includes(searchTerm) || '') ||
      (item.phone?.toLowerCase().includes(searchTerm) || '') ||
      (item.appointmentIdentifier?.toLowerCase().includes(searchTerm) || '')
    );

    // apellidos lo convierte en iniciales mayúsculas tipo Pérez León P.L.
    this.filteredResults = this.filteredResults.map((item: any) => {
      const lastNames = item.lastName.split(' ');
      console.log('Last names', lastNames);
      const initials = lastNames.map((lastName: string) => lastName.charAt(0).toUpperCase()).join('.');
      item.lastNameSplit = initials;

      console.log('Initials', initials);

      return item;
    });

    console.log('Filtered results', this.filteredResults);
  }

  confirm(appointment:any) {
    console.log('Confirm', appointment);

    this.data.createShift({ shiftType_id: appointment.appointmentType_id }).subscribe(
      (res: any) => {
        console.log(res);
        this.res = res;

        this.data.updateAppointment({ status_id: 10, shift_id: this.res.cita.id }, appointment.id).subscribe(
          (res: any) => {
            console.log(res);
          },
          (error: any) => {
            console.error(error);
          }
        );
        this.router.navigate(['/shift/success'], { state: { res: this.res } });
      },
      (error: any) => {
        console.error(error);
        this.router.navigate(['/shift/error']);
      }
    );
  }

}
