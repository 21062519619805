<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
</div>
<div class="internal-content marketing-update">
    <form class="form-control-submit" [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <h4>Recomendamos un banner de 1400x250px en jpg o png</h4>
        <input accept=".jpg, .png, .jpeg, .webp" type="file" id="file" (change)="onFileSelected($event)">
        <h2>¿Donde se visualizará?</h2>
        <mat-checkbox formControlName="monitor" class="form-control">Mostrar en monitor</mat-checkbox>
        <mat-checkbox formControlName="solicitud" class="form-control">Mostrar en solicitud</mat-checkbox>

        <div class="button-content">
            <button type="submit" class="btn btn-secondary btn-medium">Crear</button>
        </div>
    </form> 
</div>