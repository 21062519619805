<div class="appointment-main">
    <div class="appointment-box">
        <!-- <div class="appointment-header">
            <img src="{{logotipo}}">
        </div> -->
        <div class="appointment-form">
            <div class="steper-box">
                <img src="{{logotipo2}}">
                <div class="steper">
                    <div class="step" *ngFor="let step of steps" [class.active]="step.id == currentStep">
                        <div class="step-number">{{step?.id}}</div>
                        <div class="step-name">{{step?.title}}</div>
                    </div>
                </div>
            </div>
            <div class="step step-one" *ngIf="currentStep == 1">
                <h3>{{category?.name}}</h3>
                <div class="calendar-box">
                    <div class="calendar">
                        <div class="calendar-container">
                            <ngb-datepicker [(ngModel)]="model" (dateSelect)="onDateSelect($event)" 
                                            [dayTemplate]="tDay" outsideDays="hidden"></ngb-datepicker>
                          </div>
                          
                          <ng-template #tDay let-date let-currentMonth="currentMonth" let-disabled="disabled">
                            <div [class.date-with-cita]="tieneCita(date)" 
                                 [class.disabled]="!tieneCita(date) || date.month !== currentMonth"
                                 [class.selected]="model && model.day === date.day && model.month === date.month && model.year === date.year">
                              {{ date.day }}
                            </div>
                          </ng-template>
                          
                    </div>
                    <div class="select-hour" *ngIf="hours.length > 0">
                        <h4>Seleccione un horario</h4>
                        <div class="hour-box">
                            <div class="hour" *ngFor="let hour of hours" 
                                 [class.hour-unavailable]="!hour.available" 
                                 (click)="hour.available && selectHour(hour)">
                                <div class="hour-time">{{hour.hour}}</div>
                                <div class="hour-status" 
                                     [class.hour-selected]="hourSelected == hour.hour">
                                    <span *ngIf="hour.available">{{hour.slots}} cita<span *ngIf="hour.slots > 1">s</span></span>
                                    <span *ngIf="!hour.available">No disponible</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="select-hour" *ngIf="date != null && hours.length == 0">
                        <div class="hour-box">
                            <div class="no-availability-hours">
                                No hay citas disponibles para este día
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step step-two" *ngIf="currentStep == 2">
                <div class="appointment-web-box">
                    <div class="appointment-summary">
                        <h3>{{steps[currentStep-1].title}}</h3>
                        <div class="appointment-summary-item">
                            <div class="appointment-summary-label">Departamento</div>
                            <div class="appointment-summary-value">{{category?.name}}</div>
                        </div>
                        <div class="appointment-summary-item">
                            <div class="appointment-summary-label">Fecha y hora</div>
                            <div class="appointment-summary-value">{{selectedDate}}</div>
                        </div>
                        <button class="btn btn-secondary" *ngIf="currentStep > 1" (click)="previousStep()">Cambiar fecha / hora</button>
                    </div>
                    <form [formGroup]="personalDataForm" (ngSubmit)="onSubmit()" autocomplete="off">
                        <div class="form-group">
                            <label for="firstName">Nombre</label>
                            <input type="text" id="firstName" formControlName="firstName" class="form-control" placeholder="Ingrese su nombre" autocomplete="off" autocorrect="off">
                        </div>
                        <div class="form-group">
                            <label for="lastName">Apellidos</label>
                            <input type="text" id="lastName" formControlName="lastName" class="form-control" placeholder="Ingrese sus apellidos" autocomplete="off" autocorrect="off">
                        </div>
                        <div class="form-group">
                            <label for="email">Correo</label>
                            <input type="email" id="email" formControlName="email" class="form-control" placeholder="Ingrese su correo" autocomplete="off" autocorrect="off">
                        </div>
                        <div class="form-group">
                            <label for="phone">Teléfono</label>
                            <input type="tel" id="phone" formControlName="phone" class="form-control" placeholder="Ingrese su teléfono" autocomplete="off" autocorrect="off">
                        </div>
                        <div class="form-group">
                            <label for="dni">DNI</label>
                            <input type="text" id="dni" formControlName="dni" class="form-control" placeholder="Ingrese su DNI" autocomplete="off" autocorrect="off">
                        </div>
                        <button type="submit" class="btn btn-secondary">Solicitar cita</button>
                    </form>
                </div>

            </div>
            <div class="step step-three" *ngIf="currentStep == 3">
                <h3>{{steps[currentStep-1].title}}</h3>
                <div class="appointment-summary">
                    <div class="appointment-summary-item identificador">
                        <div class="appointment-summary-label">Identificador</div>
                        <div class="appointment-summary-value">{{resume?.appointmentIdentifier}}</div>
                    </div>
                    <div class="appointment-summary-item">
                        <div class="appointment-summary-label">Departamento</div>
                        <div class="appointment-summary-value">{{category?.name}}</div>
                    </div>
                    <div class="appointment-summary-item">
                        <div class="appointment-summary-label">Fecha y hora</div>
                        <div class="appointment-summary-value">{{resume?.datetime | date:'dd/MM/yyyy HH:mm'}}h</div>
                    </div>
                    <div class="appointment-summary-item">
                        <div class="appointment-summary-label">Nombre</div>
                        <div class="appointment-summary-value">{{resume?.firstName}}</div>
                    </div>
                    <div class="appointment-summary-item">
                        <div class="appointment-summary-label">Apellidos</div>
                        <div class="appointment-summary-value">{{resume?.lastName}}</div>
                    </div>
                    <div class="appointment-summary-item">
                        <div class="appointment-summary-label">Correo</div>
                        <div class="appointment-summary-value">{{resume?.email}}</div>
                    </div>
                    <div class="appointment-summary-item">
                        <div class="appointment-summary-label">Teléfono</div>
                        <div class="appointment-summary-value">{{resume?.phone}}</div>
                    </div>
                    <div class="appointment-summary-item">
                        <div class="appointment-summary-label">DNI</div>
                        <div class="appointment-summary-value">{{resume?.dni}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="appointment-footer">
            <button class="btn btn-secondary" [routerLink]="['/appointment']">Volver a categorías</button>
        </div> -->
        <div class="loading loadpage" *ngIf="loading">
            <div class="loading-content">
                <div class="loading-icon">
                    <img src="assets/oval.svg" alt="Cargando">
                </div>
            </div>
        </div>
    </div>
</div>