import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-categories-create',
  templateUrl: './categories-create.component.html',
  styleUrls: ['./categories-create.component.css']
})
export class CategoriesCreateComponent implements OnInit {

  page: any = {
    title: 'Crear categoría',
    subtitle: 'Aquí puedes crear una nueva categoría'
  }

  userForm: FormGroup;
  appointmentTypes: any;
  overthecounters: any;
  id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private toastr: ToastrService
    ) {

      this.userForm = this.formBuilder.group({
        acronym: [''],
        name: [''],
        description: ['']
      });
      
     }

  ngOnInit(): void {

    const nameControl = this.userForm.get('name');
    if (nameControl) {
      nameControl.valueChanges.subscribe(value => {
        this.createAcronym(value);
      });
    }

  }

  createAcronym(value: string) {
    // coger las tres primeras letras del nombre y ponerlas en mayúsculas
    const acronym = value.substr(0, 3).toUpperCase();
    this.userForm.get('acronym')?.setValue(acronym);
  }

  onSubmit() {
    // asegurarse que acronym esté en mayúsculas
    const acronym = this.userForm.get('acronym')?.value.toUpperCase();
    this.userForm.get('acronym')?.setValue(acronym);
    
    this.data.createShiftType(this.userForm.value).subscribe(() => {
      this.router.navigate(['/admin/categories']);
      this.toastr.success('Categoría creada con éxito', 'Categoría creada');
    }, (error) => {
      console.log('testing', error)
      this.toastr.error('Error al crear la categoría', 'Error');
    });
  }



}
