import {Component, ViewChild, OnInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';
import { environment } from 'src/environments/environment';

export interface dataUser {
  created_at: string;
  updated_at: string;
  monitor: string;
  solicitud: string;
  imagen: string;
  id: number;
}


@Component({
  selector: 'app-marketing-list',
  templateUrl: './marketing-list.component.html',
  styleUrls: ['./marketing-list.component.css']
})

export class MarketingListComponent implements OnInit {
  page = {
    title: 'Lista de banners',
    subtitle: 'Aquí puedes ver la lista de banners del sistema'
  }

  banners: dataUser[] = [];

  displayedColumns: string[] = [ 'id', 'imagen', 'monitor', 'solicitud','actions'];
  dataSource = new MatTableDataSource(this.banners);

  storageURL = environment.storageURL;

  constructor(private data: DataService, private toastr: ToastrService) { 
  }


  ngOnInit(): void {
    this.getBanners()
  }

  getBanners() {
    this.data.getBanner().subscribe((res: any) => {
      this.banners = res;
      console.log(this.banners);
      this.dataSource.data = this.banners;
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeImage(id: number) {
    // mensaje de confirmación de borrado
    if (confirm('¿Estás seguro de borrar este banner?')) {
      this.data.deleteBanner(id).subscribe((res: any) => {
        this.getBanners();
        this.toastr.success('Banner borrado correctamente');
      });
    }
  }


}

