import {Component, ViewChild, OnInit, TemplateRef} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';
import { environment } from 'src/environments/environment';

export interface dataUser {
  created_at: string;
  updated_at: string;
  acronym: string;
  description: string;
  name: string;
  id: number;
}


@Component({
  selector: 'app-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.css']
})

export class OptionsListComponent implements OnInit {
  page = {
    title: 'Lista de opciones',
    subtitle: 'Aquí puedes ver la lista de opciones del sistema'
  }

  options: dataUser[] = [];

  displayedColumns: string[] = [ 'id', 'name_options', 'status', 'options_type', 'actions'];
  dataSource = new MatTableDataSource(this.options);
  types: any;
  model: NgbDateStruct | null = null;
  noAvailabilityDays: Date[] = [];
  selectedCategories: any = [];
  selectedDate: any;
  selectedDay: any;
  noAvailabilityDayList: any = [];

  isDayNotAvailable: boolean = false;

  allSelected = false;

  mode: number = environment.mode;

  constructor(private data: DataService, private toast: ToastrService) { 
  }


  ngOnInit(): void {
    this.getOptions();
    if (this.mode === 1) {
      this.getAppointmentTypes();
      this.getNoAvailabilityDay();
    }
  }

  getOptions() {
    this.data.getOptions().subscribe((res: any) => {
      this.options = res;
      console.log(this.options);
      this.dataSource.data = this.options;
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getAppointmentTypes() {
    this.data.getShiftTypes().subscribe((res: any) => {
      this.types = res;
      // crea un array de categorias id así [1, 2, 3, 4, 5]
      const categories = this.types.map((type: any) => type.id);
      this.getCalendarDays(categories);

    });
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.allSelected = false;
      this.selectedCategories = [];
      this.types.forEach((type: any) => type.selected = false);
    } else {
      this.allSelected = true;
      this.selectedCategories = this.types.map((type: any) => type.id);
      this.types.forEach((type: any) => type.selected = true);
    }
    this.dataSource.data.forEach((row: any) => row.selected = this.allSelected);
  }

  onCategoryChange(id: any) {
    if (this.allSelected) {
      this.allSelected = false;
    } else {
      const index = this.selectedCategories.indexOf(id);
      if (index === -1) {
        this.selectedCategories.push(id);
      } else {
        this.selectedCategories.splice(index, 1);
      }
    }
  }

   // Método para verificar si un día tiene cita
   tieneCita(fecha: NgbDateStruct): boolean {
    const date = new Date(fecha.year, fecha.month - 1, fecha.day);
    return this.noAvailabilityDays.some(cita =>
      cita.getFullYear() === date.getFullYear() &&
      cita.getMonth() === date.getMonth() &&
      cita.getDate() === date.getDate()
    );
  }

  onDateSelect(date: NgbDateStruct) {
    if (this.tieneCita(date)) {
      this.isDayNotAvailable = true;
      console.log('Cita seleccionada:', date);
      this.selectedDate = date;
      // date string format 2024-09-20 teniendo en cuenta que los digitos deben ser minimo 2 digitos
      const dateStr = date.year + '-' + ('0' + date.month).slice(-2) + '-' + ('0' + date.day).slice(-2);

      // busca la id entre noAvailabilityDayList y el dateStr 
      const day = this.noAvailabilityDayList.find((day: any) => day.day === dateStr);
      this.selectedDay = day;
      // llamo a selectedCategories para que me muestre las categorias seleccionadas convirtiendolas en un array de id de categorias
      this.selectedCategories = day ? JSON.parse(day.type) : [];
      // selecciono los checkbox de categorías que aparezcan en el array de categorias seleccionadas
      this.types.forEach((type: any) => type.selected = this.selectedCategories.includes(type.id));

      console.log('Categorias seleccionadas:', this.selectedCategories);

      console.log('Día seleccionado:', day);

      

    } else {
      this.isDayNotAvailable = false;
      this.selectedDate = date;
      // pongo las categorias seleccionadas a vacio y los checkbox
      this.selectedCategories = [];
      this.types.forEach((type: any) => type.selected = false);
      
      console.log('No hay citas disponibles para este día', date);
    }
  }

  getCalendarDays(categories: any) {
    this.data.getCalendarDays(categories).subscribe((res: any) => {
      
      // crea una array de fechas en formato NgbDateStruct con formato new Date ('2024-09-21') para noAvaibleDates
      const notAvaibleDates = res.map((day: any) => {
        const date = new Date(day.day);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      });
   
      this.noAvailabilityDays = notAvaibleDates;
    });
  }

  saveData() {
    console.log('Datos guardados', this.selectedCategories, this.selectedDate);
    // monta el día en formato 2024-09-21 teniendo en cuenta que los digitos deben ser minimo 2 digitos y que es una array de NgbDateStruct con formato new Date ('2024-09-21') para
    const dateStr = this.selectedDate.year + '-' + ('0' + this.selectedDate.month).slice(-2) + '-' + ('0' + this.selectedDate.day).slice(-2);
    
    const data = {
      day: dateStr,
      type: this.selectedCategories
    };
    if (this.isDayNotAvailable) {
      this.updateNoAvailabilityDays(data);
    } else {
      this.createNoAvailabilityDays(data);
    }
  }

  createNoAvailabilityDays(data: any) {
    this.data.createNoAvailabilityDay(data).subscribe(
      (res: any) => {
      console.log(res);
      this.toast.success('Día de no disponibilidad creado exitosamente');
      const categories = this.types.map((type: any) => type.id);
      this.getCalendarDays(categories);
      this.getNoAvailabilityDay();
      },
      (error: any) => {
      console.error('Error al crear día de no disponibilidad:', error);
      this.toast.error('Error al crear día de no disponibilidad');
      }
    );

  }

  updateNoAvailabilityDays(data: any) {
    this.data.updateNoAvailabilityDay(data, this.selectedDay.id).subscribe(
      (res: any) => {
      console.log(res);
      this.toast.success('Día de no disponibilidad actualizado exitosamente');
      const categories = this.types.map((type: any) => type.id);
      this.getCalendarDays(categories);
      this.getNoAvailabilityDay();
      },
      (error: any) => {
      console.error('Error al actualizar día de no disponibilidad:', error);
      this.toast.error('Error al actualizar día de no disponibilidad');
      }
    );
  }

  getNoAvailabilityDay() {
    this.data.getNoAvailabilityDay().subscribe(
      (res: any) => {
        this.noAvailabilityDayList = res;
        console.log('no disponibilidad', this.noAvailabilityDayList);
      },
      (error: any) => {
        console.error('Error fetching no availability days:', error);
      }
    );
  }

}

