<div class="appointment-main">
    <div class="appointment-box">
        <div class="appointment-header">
            <img src="{{logotipo}}">
        </div>
        <div class="appointment-form">
            <h1>
                Este servicio no se encuentra disponible en este momento
            </h1>
            <h2>Disculpe las molestias</h2>
        </div>
        <div class="appointment-footer">
           
        </div>        
    </div>
</div>