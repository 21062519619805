import {Component, ViewChild, OnInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/services/services';

export interface dataUser {
  created_at: string;
  updated_at: string;
  acronym: string;
  description: string;
  name: string;
  id: number;
}


@Component({
  selector: 'app-overthecounter-list',
  templateUrl: './overthecounter-list.component.html',
  styleUrls: ['./overthecounter-list.component.css']
})

export class OverthecounterListComponent implements OnInit {
  page = {
    title: 'Lista de mesas',
    subtitle: 'Aquí puedes ver la lista de mesas del sistema y crear nuevas ubicaciones'
  }

  overthecounter: dataUser[] = [];

  displayedColumns: string[] = [ 'id', 'name', 'description','actions'];
  dataSource = new MatTableDataSource(this.overthecounter);

  constructor(private data: DataService) { 
  }


  ngOnInit(): void {
    this.getOverthecounter()
  }

  getOverthecounter() {
    this.data.getOverthecounter().subscribe((res: any) => {
      this.overthecounter = res;
      console.log(this.overthecounter);
      this.dataSource.data = this.overthecounter;
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}

