import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/services';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {

  types: any;
  res: any;
  logotipo: any;

  constructor(
   private router: Router,
   private data: DataService
  ) { 
  }

  ngOnInit(): void {
    this.getAppointmentTypes();
    this.logotipo = environment.logotipo;
  }

  getAppointmentTypes() {
    console.log('Get appointment types');
    this.data.getShiftTypes().subscribe((res: any) => {
      console.log(res);
      this.types = res;
    });
  }

  nextStep(id: any): void {
    console.log('Next step', id);

    this.router.navigate(['/appointment/create'], { state: { res: id } });

    // this.data.createShift({ appointmentType_id: id }).subscribe(
    //   (res: any) => {
    //     console.log(res);
    //     this.res = res;
    //     this.router.navigate(['/shift/success'], { state: { res: this.res } });
    //   },
    //   (error: any) => {
    //     console.error(error);
    //     this.router.navigate(['/shift/error']);
    //   }
    // );
  }
}
