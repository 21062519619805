<div class="appointment-main error">
    <div class="appointment-box">
        <div class="appointment-header">
            <img src="{{logotipo}}">
        </div>
        <div class="appointment-form">
            <h3>Se ha producido un error intentando realizar su solicitud</h3>
            <button class="btn btn-primary btn-medium" (click)="return()" >Volver a intentar</button>
        </div>
        <div class="appointment-footer">
            <!-- <button class="btn btn-secondary">Volver a inicio</button> -->
        </div>
    </div>
</div>