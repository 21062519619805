<div class="internal-subheader"></div>
<div class="container">
    <h2>{{page.title}}</h2>
    <h5>{{page.subtitle}}</h5>
</div>
<div class="internal-content">
    <form [formGroup]="appointmentForm" (ngSubmit)="updateAppointment()">
        <div class="form-group">
            <label for="appointmentIdentifier">Identificador de Cita</label>
            <input type="text" class="form-control" id="appointmentIdentifier" formControlName="appointmentIdentifier" readonly>
        </div>
        <div class="form-group">
            <label for="firstName">Nombre</label>
            <input type="text" class="form-control" id="firstName" formControlName="firstName">
        </div>
        <div class="form-group">
            <label for="lastName">Apellido</label>
            <input type="text" class="form-control" id="lastName" formControlName="lastName">
        </div>
        <div class="form-group">
            <label for="dni">DNI</label>
            <input type="text" class="form-control" id="dni" formControlName="dni">
        </div>
        <div class="form-group">
            <label for="phone">Teléfono</label>
            <input type="text" class="form-control" id="phone" formControlName="phone">
        </div>
        <div class="form-group">
            <label for="email">Correo Electrónico</label>
            <input type="email" class="form-control" id="email" formControlName="email">
        </div>
        <div class="form-group">
            <label for="appointmentType_id">Categoría de Cita</label>
            <select class="form-control" id="appointmentType_id" formControlName="appointmentType_id">
                <option *ngFor="let category of types" [value]="category.id">{{category.name}}</option>
            </select>
        </div>

        <div class="form-group shift-info-group">
            <div class="shift-info">
                <label>Estado</label>
                <p>{{appointment?.state?.name}}</p>
            </div>
            <div class="shift-info" *ngIf="shift">
                <label>Turno</label>
                <p>{{shift?.shiftIdentifier}}</p>
            </div>
            <div class="shift-info" *ngIf="shift">
                <label>Solicitado a las </label>
                <p>{{shift?.created_at | date:'dd-MM-yyyy HH:mm'}}</p>
            </div>
            <div class="shift-info" *ngIf="shift">
                <label>Atendido a las </label>
                <p>{{shift?.updated_at | date:'dd-MM-yyyy HH:mm'}}</p>
            </div>
        </div>
        <div class="form-group">
            <label for="datetime">Fecha y Hora</label>
            <input type="datetime-local" class="form-control" id="datetime" formControlName="datetime">
        </div>
        <button type="submit" class="btn btn-primary">Guardar</button>
    </form>
</div>
