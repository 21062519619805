import {Component, ViewChild, OnInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/services/services';

export interface dataUser {
  created_at: string;
  updated_at: string;
  acronym: string;
  description: string;
  name: string;
  id: number;
  start_time: string;
  end_time: string;
  simultaneous_appointments: number;
  time_interval: number;
}


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})

export class CategoriesComponent implements OnInit {
  page = {
    title: 'Lista de categorías',
    subtitle: 'Aquí puedes ver la lista de categorías del sistema'
  }

  types: dataUser[] = [];

  displayedColumns: string[] = ['id', 'name', 'acronym', 'description', 'start_time', 'time_interval', 'simultaneous_appointments', 'actions'];
  dataSource = new MatTableDataSource(this.types);

  constructor(private data: DataService) { 
  }


  ngOnInit(): void {
    this.getTypes()
  }

  getTypes() {
    this.data.getShiftTypes().subscribe((res: any) => {
      this.types = res;
      console.log(this.types);
      this.dataSource.data = this.types;
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}

