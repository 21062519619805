<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
</div>
<div class="internal-content">

    <div class="dashboard-box">
        <div class="dashboard-item">
            <h3>Citas pendientes totales</h3>
            <p class="ap-info"> {{appointments?.length}} </p>
        </div>
        <div class="dashboard-item">
            <h3>Mis citas pendientes</h3>
            <p class="ap-info">{{myAppointments?.length}} </p>
        </div>
        <div class="dashboard-item">
            <h3>Puesto de trabajo</h3>
            <p class="ap-info">{{loggedUser?.over_the_counter?.name}}</p>
        </div>
    </div>

</div>