import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ShiftComponent } from './pages/shift/shift.component';
import { ErrorComponent } from './pages/shift/error/error.component';
import { SuccessComponent } from './pages/shift/success/success.component';
import { MaintenanceComponent } from './pages/shift/maintenance/maintenance.component';
import { MonitorComponent } from './pages/monitor/monitor.component';
import { LoginComponent } from './pages/admin/login/login.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { UserCreateComponent } from './pages/admin/user-create/user-create.component';
import { UserEditComponent } from './pages/admin/user-edit/user-edit.component';
import { UserListComponent } from './pages/admin/user-list/user-list.component';
import { ShiftListComponent } from './pages/admin/shift-list/shift-list.component';
import { ShiftDetailsComponent } from './pages/admin/shift-details/shift-details.component';
import { CategoriesComponent } from './pages/admin/categories/categories.component';
import { MarketingListComponent } from './pages/admin/marketing-list/marketing-list.component';
import { MarketingCreateComponent } from './pages/admin/marketing-create/marketing-create.component';
import { MarketingEditComponent } from './pages/admin/marketing-edit/marketing-edit.component';
import { MarketingDetailsComponent } from './pages/admin/marketing-details/marketing-details.component';

import { AuthGuard } from './auth.guard'; // Importar el guardia de autenticación
import { CategoryComponent } from './pages/shift/category/category.component';
import { AdminComponent } from './pages/admin/admin.component';
import { CategoriesEditComponent } from './pages/admin/categories-edit/categories-edit.component';
import { CategoriesCreateComponent } from './pages/admin/categories-create/categories-create.component';
import { OverthecounterListComponent } from './pages/admin/overthecounter-list/overthecounter-list.component';
import { OverthecounterCreateComponent } from './pages/admin/overthecounter-create/overthecounter-create.component';
import { OverthecounterEditComponent } from './pages/admin/overthecounter-edit/overthecounter-edit.component';
import { OptionsListComponent } from './pages/admin/options-list/options-list.component';
import { OptionsCreateComponent } from './pages/admin/options-create/options-create.component';
import { OptionsEditComponent } from './pages/admin/options-edit/options-edit.component';
import { ClosedComponent } from './pages/shift/closed/closed.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { CreateAppointmentComponent } from './pages/appointment/create-appointment/create-appointment.component';
import { AppointmentListComponent } from './pages/admin/appointment-list/appointment-list.component';
import { AppointmentDetailsComponent } from './pages/admin/appointment-detail/appointment-details.component';
import { ValidateComponent } from './pages/shift/validate/validate.component';


const routes: Routes = [
  { path: '', redirectTo: 'shift', pathMatch: 'full' },
  { path: 'shift', children: [
      { path: '', component: ShiftComponent },
      { path: 'category', component: CategoryComponent },
      { path: 'error', component: ErrorComponent },
      { path: 'success', component: SuccessComponent },
      { path: 'validate', component: ValidateComponent },
      { path: 'maintenance', component: MaintenanceComponent },
      { path: 'closed', component: ClosedComponent },
    ]
  },
  { path: 'appointment', children: [
      { path: '', component: AppointmentComponent },
      { path: 'create', component: CreateAppointmentComponent },
      { path: 'category', component: CategoryComponent },
      { path: 'error', component: ErrorComponent },
      { path: 'success', component: SuccessComponent },
      { path: 'maintenance', component: MaintenanceComponent },
      { path: 'closed', component: ClosedComponent },
    ]
  },
  { path: 'monitor', component: MonitorComponent },
  { path: 'admin/login', component: LoginComponent }, // Ruta de inicio de sesión fuera de 'admin'
  {
    path: 'admin',
    canActivate: [AuthGuard], // Protege todas las rutas bajo 'admin'
    component: AdminComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'users/add', component: UserCreateComponent },
      { path: 'users/edit/:id', component: UserEditComponent },
      { path: 'users', component: UserListComponent },
      { path: 'shift', component: ShiftListComponent },
      { path: 'shift/details/:id', component: ShiftDetailsComponent },
      { path: 'appointment', component: AppointmentListComponent },
      { path: 'appointment/details/:id', component: AppointmentDetailsComponent },
      { path: 'categories', component: CategoriesComponent },
      { path: 'categories/add', component: CategoriesCreateComponent },
      { path: 'categories/edit/:id', component: CategoriesEditComponent },
      { path: 'overthecounter', component: OverthecounterListComponent },
      { path: 'overthecounter/add', component: OverthecounterCreateComponent },
      { path: 'overthecounter/edit/:id', component: OverthecounterEditComponent },
      { path: 'marketing', component: MarketingListComponent },
      { path: 'marketing/add', component: MarketingCreateComponent },
      { path: 'marketing/edit/:id', component: MarketingEditComponent },
      { path: 'options', component: OptionsListComponent },
      { path: 'options/add', component: OptionsCreateComponent },
      { path: 'options/edit/:id', component: OptionsEditComponent },
    ]
  },
  { path: '**', redirectTo: '/shift' } // Redireccionar cualquier ruta no definida a /appointment
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],  
  exports: [RouterModule]
})
export class AppRoutingModule { }
