import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DataService } from 'src/app/services/services';
import { environment } from 'src/environments/environment';

// import Swiper core and required modules
import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation, Swiper } from 'swiper';


@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.css']
})
export class ShiftComponent implements OnInit {

  storageURL = environment.storageURL;
  banners: any = [];
  generalOptions: any = [];

  configuration: any = {
    mode: environment.mode,
    banners: environment.marketing,
  }

  config: SwiperOptions = {
    slidesPerView: 1,
    allowTouchMove: false,
    spaceBetween: 0,
    navigation: false,
    pagination: { clickable: false },
    scrollbar: { draggable: false },
    autoplay: {
      delay: 5000,
    },
  };

  intervalId: any;

  logotipo: any;

  constructor(
   private router: Router,
   private data: DataService
  ) { 

    // Instala el módulo Autoplay
    SwiperCore.use([Autoplay, Pagination, Navigation]);
  }

  ngOnInit(): void {
    this.getBanners();
    this.getOptions();
    this.logotipo = environment.logotipo;

    this.intervalId = setInterval(() => {
      this.getBanners();
      this.getOptions();
    }, 10000);
  }

  nextStep(mode: any) {
    // parar el interval de onInit
    clearInterval(this.intervalId);

    if(mode == 'next') {
      this.router.navigate(['/shift/category']);
    } else if (mode == 'validate') {
      console.log('error')
      this.router.navigate(['/shift/validate']);
    }

    // this.router.navigate(['/shift/category']);
    // console.log('Next step');    


  }

  onSlideChange() {
    console.log('slide change');
  }

  getBanners() {
    this.data.getBanner().subscribe((res: any) => {
      const bannersSolicitud = res.filter((item: any) => item.solicitud == '1');
      this.banners = bannersSolicitud;
      console.log('banners', this.banners);
    });
  }

  getOptions() {
    this.data.getOptions().subscribe((res: any) => {
      this.generalOptions = res;
      console.log('generalOptions', this.generalOptions);
      // busca options_type = cerrado y recoge el status
      const statusClosed = this.generalOptions.find((item: any) => item.options_type == 'cerrado');
      console.log('statusClosed', statusClosed);
      if(statusClosed && statusClosed.status == '1') {
        console.log('statusClosed', statusClosed.status);
          this.router.navigate(['/shift/closed']);
      }
      // busca options_type = mantenimiento y recoge el status
      const statusMaintenance = this.generalOptions.find((item: any) => item.options_type == 'mantenimiento');
      console.log('statusMaintenance', statusMaintenance);
      if(statusMaintenance && statusMaintenance.status == '1') {
        console.log('statusMaintenance', statusMaintenance.status);
          this.router.navigate(['/shift/maintenance']);
      }
      
    });
  }

}
