import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/services';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

// import Swiper core and required modules
import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';

import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements OnInit {
  todayDate = new Date()
  nowHour;
  appointments: any = [];
  upcoming: any = [];
  upcomingGroup: any = [];
  rightnow: any = [];
  groupSize = 12; // Número de elementos por grupo

  horizontal = false;

  ahoramismo = moment().format('LTS')

  time = new Date();
  rxTime = new Date();
  intervalId: any;
  checkupdates: any;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    pagination: { el: '.swiper-dots', clickable: true },
    scrollbar: { draggable: true },
    autoplay: {
      delay: 5000,
    },
  };


  configMarketing: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    scrollbar: { draggable: true },
    autoplay: {
      delay: 5000,
    },
  };

  storageURL = environment.storageURL;
  banners: any = [];

  logotipo: any;

  constructor(private data: DataService, private router: Router) {

    this.horizontal = environment.horizontal;
    
    moment.locale('es');
    // const mockupDate = '2023-05-15 09:57:00';
    const now = new Date();
    // const now = new Date(mockupDate);
    this.nowHour = this.getHour(now);

    // Instala el módulo Autoplay
    SwiperCore.use([Autoplay, Pagination, Navigation]);
  }


  ngOnInit() {
    this.getData();
    this.logotipo = environment.logotipo;
    // carga sonido notificacion
    // this.getNotification()



    // reloj general
    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000);


    this.checkupdates = setInterval(() => {
      this.getData()

    }, 15000);

  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.checkupdates);

  }

  // onSwiper([swiper]: any[]) {
  //   console.log(swiper);
  // }
  // onSlideChange() {
  //   console.log('slide change');
  // }

  getData() {


    this.getBanners()

    // llamamos a la api y nos traemos las citas
    this.data.getShift().subscribe((res: any) => {

      // ordenamos la api por orden de hora de menor a mayor
      res.sort((a: any, b: any) => {
        return <any>new Date(a.start_date) - <any>new Date(b.start_date);
      });


      const appointmentsNow = JSON.stringify(this.appointments)
      const appointmentNew = JSON.stringify(res)
      this.appointments = res;

      if (appointmentNew == appointmentsNow) {
      } else {
        console.log('entro aqui oprque no es igual...');
        // asignamos las citas a la variable
        this.getNotification();
        // llamamos a la función que asigna las citas actuales y las proximas
        this.getAppointment();
      }

      console.log('respuesta', res)
      console.log('upcoming', this.upcoming)
      console.log('rightnow', this.rightnow)
    }, (err) => {
      console.log(err);
    });
  }

  getAppointment() {
    this.upcoming = [];
    this.rightnow = [];
    this.upcomingGroup = [];

      // hacemos el bucle para comprobar las fechas
      this.appointments.forEach((item:any, index:any) => {

        const isUpcoming = moment(item.start_date).isSameOrAfter(this.time);
        const isEnd = moment(item.end_date).isSameOrAfter(this.time);
        
        // console.log('isUpcoming>>>', item.id, isUpcoming);
        // console.log('isEnd>>>', item.id, isEnd);

        // if (isUpcoming === true && item.customer_appointment.status === 'en-espera') {
        //   this.upcoming.push(item);
        // } else if((isUpcoming === false && isEnd === true) || item.customer_appointment.status === 'puede-pasar') {
        //   this.rightnow.push(item);
        // }

        if (item.status_id === 1) {
          this.upcoming.push(item);
        } else if(item.status_id === 2) {
          this.rightnow.push(item);
        }

      });


      for (let i = 0; i < this.upcoming.length; i += this.groupSize) {
        this.upcomingGroup.push(this.upcoming.slice(i, i + this.groupSize));
      }
      console.log('this.upcomingGroup', this.upcomingGroup);
  }


  getHour(date: any) {
    let HH: any = date.getHours()
    let MM: any = date.getMinutes()
    if (HH < 10) HH = '0' + HH;
    if (MM < 10) MM = '0' + MM;
    return HH + ':' + MM;
}

getHourWithSeconds(date: any) {
      let HH: any = date.getHours()
      let MM: any = date.getMinutes()
      let SS: any = date.getSeconds()
      if (HH < 10) HH = '0' + HH;
      if (MM < 10) MM = '0' + MM;
      if (SS < 10) SS = '0' + SS;
      return HH + ':' + MM + ':' + SS;
}

  getNotification() {
    const audio = <HTMLAudioElement> document.getElementById('notification');
    audio.play(); 
  }

  checkAppointment() {
    this.getData(); 
    this.appointments.forEach((item:any) => {
      const nowDate = moment(this.time).format('YYYY-MM-DD HH:mm:ss')
      const eventNow = moment(item.start_date).isSame(nowDate);

      console.log('eventNow', item.start_date, nowDate, eventNow, item.customer_appointment.status)

      if (eventNow || item.customer_appointment.status === 'puede-pasar') {
        console.log('tengo una cita', eventNow);
        this.getData();
        this.getNotification();
      }
    });

  }

  getBanners() {
    this.data.getBanner().subscribe((res: any) => {
      const bannersMonitor = res.filter((item: any) => item.monitor == '1');
      this.banners = bannersMonitor;
      // console.log('banners', this.banners);
    });
  }


}
