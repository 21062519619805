<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
</div>
<div class="internal-content">
    <form class="form-control-submit" [formGroup]="userForm" (ngSubmit)="onSubmit()">
        


        <mat-form-field>
            <mat-label for="name">Nombre</mat-label>
            <input matInput type="text" class="form-control" id="name" formControlName="name">
        </mat-form-field>
        <mat-form-field>
            <mat-label for="description">Descripción</mat-label>
            <input matInput type="text" class="form-control" id="description" formControlName="description">
        </mat-form-field>
        <div class="button-content">
            <button type="submit" class="btn btn-secondary btn-medium">Editar</button>
            <button type="button" class="btn btn-secondary btn-medium btn-danger" (click)="onDelete()">Eliminar</button>
        </div>
    </form> 
</div>