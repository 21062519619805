<div class="appointment-main success">
    <div class="appointment-box">
        <div class="appointment-header">
            <img src="{{logotipo}}">
        </div>
        <div class="appointment-form">
            <h3>SU TURNO</h3>
            <button class="btn btn-primary btn-extrabig">{{data.cita.shiftIdentifier}}</button>
            <p> {{ date | date: 'dd/MM/yyyy'}}  {{ date | date: 'HH:mm'}}</p>
           
        </div>
        <div class="appointment-footer">
            <button class="btn btn-secondary" [routerLink]="['/shift']" routerLinkActive="router-link-active" >Volver a categorías</button>
        </div>
    </div>
</div>