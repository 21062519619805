import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const isLoggedIn = localStorage.getItem('token') ? true : false;

    if (isLoggedIn) {
      return true; // Si el usuario está autenticado, permitir acceso
    } else {
      // Si el usuario no está autenticado, redirigir al login y guardar la URL actual para redirigir después del inicio de sesión
      this.router.navigate(['/admin/login'], { queryParams: { returnUrl: state.url }});
      return false; // Bloquear el acceso a la ruta protegida
    }
  }
}
