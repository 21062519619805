import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  logotipo: any;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.logotipo = environment.logotipo;
  }

  return() {
    console.log('Return');
    this.router.navigate(['/appointment']);
  }

}
