import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-marketing-create',
  templateUrl: './marketing-create.component.html',
  styleUrls: ['./marketing-create.component.css']
})
export class MarketingCreateComponent implements OnInit {

  page: any = {
    title: 'Crear banner',
    subtitle: 'Aquí puedes crear un nuevo banner'
  }

  userForm: FormGroup;
  appointmentTypes: any;
  overthecounters: any;
  id: any;
  file: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private toastr: ToastrService
    ) {

      this.userForm = this.formBuilder.group({
        monitor: [''],
        solicitud: ['']
      });
      
     }

  ngOnInit(): void {

  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  onSubmit() {
    this.data.createBanner(this.userForm.value, this.file).subscribe(() => {
      this.router.navigate(['/admin/marketing']);
      this.toastr.success('Banner creado correctamente');
    }, (error) => {
      this.toastr.error('Error al crear el banner');
    }
    );
  }



}
