import {Component, ViewChild, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';
import { Title } from '@angular/platform-browser';


export interface dataUser {
  shiftIdentifier: number;
  created_at: string;
  email: string;
  dni: string;
  appointmentType_id: number;
  employeeNumber: string;
  status_id: number;
  type: {
    id: number,
    name: string,
    acronyms: string
  },
  employee: { 
    firstName: string,
    lastName: string,
    over_the_counter: {
      id: number,
      name: string
    }
  };
  firstName: string;
  phone: string;
  status: number;
  id: number;
  lastName: string;
}

@Component({
  selector: 'app-shift-list',
  templateUrl: './shift-list.component.html',
  styleUrls: ['./shift-list.component.css']
})
export class ShiftListComponent implements OnInit {
  page = {
    title: 'Lista de turnos',
    subtitle: 'Aquí puedes ver la lista de turnos registradas en el sistema'
  }

  loading: boolean = true;

  appointments: dataUser[] = [];
  allAppointments: dataUser[] = [];
  pendingAppointments: dataUser[] = [];
  myAppointments: dataUser[] = [];

  rightNowAppointment: any;

  displayedColumns: string[] = [ 'id', 'shiftIdentifier', 'employee', 'created_at', 'appointmentType_id', 'status','actions'];
  dataSource = new MatTableDataSource(this.appointments);

  loggedUser: any;
  categories: any[] = [];
  allCategories: any[] = [];
  filterStatusId: number = 1; // citas pendientes por defecto
  myCurrentAppointment: any;
  isAllSelected: boolean = false;

  searchControl = new FormControl();
  selectedValue: string = '';
  filterStatusIdMyAppointments: any = 1;
  filterStatusIdAllAppointments: any;

  shiftByState: any;

  total = 0;
  perPage = 100;
  currentPage = 1;

  shiftPagination: any;

  // timeToReload: number = 120000; // 2 minutos en milisegundos
  timeToReload: number = 15000; // 2 minutos en milisegundos

  public pageTitle: string | undefined;

  get filteredOptions() {
    const search = this.searchControl.value;
    if (!search) {
      return this.allCategories;
    }
    return this.allCategories.filter(categories => categories.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }


  constructor(private data: DataService, private toastr: ToastrService, private titleService: Title) { 
    
  }


  ngOnInit(): void {
    this.getLogguedUser();
    this.getShifts();

    this.getCategories();

    setInterval(() => {
    
      // comprobar si hay nuevas citas pendientes, en caso que no haya citas, no lanza mensaje toast
      this.reload();
      
     // this.toastr.info('Se ha actualizado la lista de citas');
      this.titleService.setTitle('('+this.myAppointments.length+') Lista de citas - Sistema de Citas');
    }, this.timeToReload);
  }

  resetFilters() {
    this.dataSource.data = this.myAppointments;
    this.searchControl.setValue('');
    // unselect mat-select
  this.selectedValue = '';
    this.allCategories.forEach((item) => {
      item.selected = false;
    });
  }


  getShifts() {
    this.data.getShift().subscribe((res: any) => {
      this.appointments = res;
      this.allAppointments = res;

      // 

      // recoge las citas del usuario logueado para mostrarlas en la vista
      this.data.getMyShifts(1).subscribe((res: any) => {
        // guarda la variable con las citas anteriores para comprobación posterior de cambio
        let myAppointmentsOld = this.myAppointments;
        this.loading = false;
        this.myAppointments = res;
        // console.log('this.myAppointments', this.myAppointments);
        this.titleService.setTitle('('+this.myAppointments.length+') Lista de citas - Sistema de Citas');

        console.log('length old vs real', myAppointmentsOld.length, this.myAppointments.length);
        
        // comprueba que las citas han cambiado y lanza un mensaje toast y sonido
        if (myAppointmentsOld.length != this.myAppointments.length) {
          this.filterByStatus(1);
          this.dataSource.data = this.myAppointments;
          this.filterStatusIdMyAppointments = 1;
          this.filterStatusIdAllAppointments = '';
          this.toastr.info('Se ha actualizado la lista de citas');
          const audio = <HTMLAudioElement> document.getElementById('notification');
          audio.play(); 
        }
      });



      
    });
  }

  getLogguedUser() {
    this.data.getLogguedUser().subscribe((res: any) => {
      this.loggedUser = res;
      // Recoge la cita que tiene asignada en este momento el usuario logueado
      this.data.getShiftDetails(this.loggedUser.now_appointment).subscribe((res: any) => {
        this.rightNowAppointment = res;
        console.log('this.rightNowAppointment', this.rightNowAppointment);
      });
      // recoge las categorias de citas asignadas al usuario
      this.loggedUser.assignedAppointmentType_id.forEach((appointment: any) => {
        this.data.getTypesDetails(appointment).subscribe((res: any) => {
          this.categories.push(res);
        });
      });
    });
  }

  getCategories() {
    this.data.getShiftTypes().subscribe((res: any) => {
      this.allCategories = res;
    });
  }

  

   filterByStatus(status: number) {
    console.log('status', status);
    this.filterStatusId = status;
    this.filterStatusIdAllAppointments = status;
    this.filterStatusIdMyAppointments = '';
  
    // Filtrar las citas por estado
    this.appointments = this.filterAppointmentsByStatus(status);
  
    // Actualizar la fuente de datos solo si hay cambios
    if (this.dataSource.data !== this.appointments) {
      this.dataSource.data = this.appointments;
    }
  
    // Si el estado es pendiente, guardar en un array para filtrar por categorías
    if (status === 1) {
      this.pendingAppointments = this.appointments;
    } else if( status === 99) {
      console.log('status', status);
      this.getAllShifts();

    } else {
      this.getShiftByState(status);
    }

    // else if (status === 4) {
    //   // Ordenar las citas por ID en orden descendente
    //   this.appointments = this.sortAppointmentsByIdDesc(this.appointments);
    //   this.dataSource.data = this.appointments;
    // }
  }
  
  // Función auxiliar para filtrar citas por estado
  private filterAppointmentsByStatus(status: number): dataUser[] {
    return this.allAppointments.filter(appointment => appointment.status_id === status);
  }
  
  // Función auxiliar para ordenar citas por ID en orden descendente
  private sortAppointmentsByIdDesc(appointments: dataUser[]): dataUser[] {
    return appointments.sort((a, b) => b.id - a.id);
  }

  onPageChange(page: any) {
    this.currentPage = page;
    this.currentPage = page.pageIndex + 1;
    this.getAllShifts();
  }

  filterByStatusMyAppointments(status: number) {
    this.filterStatusIdMyAppointments = status;
    this.filterStatusIdAllAppointments = '';
    this.filterStatusId = 0;
    this.dataSource.data = this.myAppointments.filter((appointment) => appointment.status_id == status);
  }

  filterByCategory(category: number) {
    console.log('categorya seleccionada>>>', category);
    this.isAllSelected = false;
    this.filterByStatus(this.filterStatusId);

    // Filtrar las citas pendientes por categoría
    if (this.filterStatusId === 1) {
      this.appointments = this.pendingAppointments.filter((appointment) => appointment?.type?.id == category);
    } else {
      this.appointments = this.allAppointments.filter((appointment) => appointment?.type?.id == category && appointment.status_id == this.filterStatusId);
    }

    this.dataSource.data = this.appointments;

    // Añadir clase css a la celda seleccionada
    this.allCategories.forEach((item) => {
      item.selected = item.id === category;
    });
  }

  // filtrar por nombre
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase(); 
  }

  applyFilterDate(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const filterDate = new Date(filterValue);
    console.log('applyFilterDate', filterDate);
    if(filterValue == '') {
      this.dataSource.data = this.appointments;
    } else {
      this.appointments = this.allAppointments.filter((appointment) => appointment.created_at.includes(filterValue));
      this.dataSource.data = this.appointments;
    }
  }

  cancelAppointment(id: number) {
    const data = {
      status_id: 3
    }
    this.data.updateShift(data, id).subscribe((res: any) => {
      this.updateNowAppointment();
      this.getShifts();
      this.toastr.success('Cita cancelada correctamente');
    });
  }

  finishAppointment(id: number, nextAppointment = false) {

    if (nextAppointment == false) {
      this.updateNowAppointment();
    }

    const data = {
      status_id: 4
    }
    this.data.updateShift(data, id).subscribe((res: any) => {
      this.getShifts();
      this.toastr.success('Cita finalizada correctamente');
    });

  }

  reasignAppointment(id: number) {
    const data = {
      status_id: 1
    }
    this.data.updateShift(data, id).subscribe((res: any) => {
      this.getShifts();
      this.toastr.success('Cita reasignada correctamente');
    });

    this.updateNowAppointment();

  }

  nextAppointment(id: number) {

    this.finishAppointment(id, true);
    // cogemos la siguiente cita disponible
    if(this.myAppointments.length > 0) {
      const nextAppointment = this.myAppointments[0];
      console.log('nextAppointment', nextAppointment);
      this.assingAppointment(nextAppointment.id);
      this.toastr.success('Se ha pasado a la siguiente cita correctamente');
    } else {
      this.toastr.warning('No hay más citas disponibles en tu categoría');
    }
   
  }

  // asignacion de cita
  assingAppointment(appointment: any) {
    // llamamos a la api para actualizar el usuario con la cita que hemos pulsado
    this.data.updateEmployeeShift({assignedAppointmentType_id: '['+this.loggedUser.assignedAppointmentType_id.toString()+']', now_appointment: appointment}, this.loggedUser.id).subscribe((res: any) => {
      // llamamos a la api para recoger los detalles de la cita que hemos pulsado
      this.data.getShiftDetails(appointment).subscribe((res: any) => {
        // guardamos la cita en una variable para mostrarla en la vista
        this.rightNowAppointment = res;
        console.log('this.rightNowAppointment', this.rightNowAppointment);
        // llamamos a la api para recoger las citas del usuario logueado
        this.data.updateShift({status_id: 2, employee_id: this.loggedUser.id}, appointment).subscribe((res: any) => {
          // recogemos las citas del usuario logueado para mostrarlas en la vista
          this.getShifts();
        });
      });
    });
  }

  // liberar cita
  updateNowAppointment() {
    // llamamos a la api para actualizar el usuario sin cita
    this.data.updateEmployeeShift({assignedAppointmentType_id: '['+this.loggedUser.assignedAppointmentType_id.toString()+']', now_appointment: null}, this.loggedUser.id).subscribe((res: any) => {
      // guardamos la cita actual en null para indicar que no tiene cita
      this.rightNowAppointment = null;
    });
  }

  reload() {
    this.getShifts();
  }

 getShiftByState(state: number) {
    this.data.getShiftsByState(state).subscribe((res: any) => {
      this.shiftByState = res;
      this.dataSource.data = this.shiftByState;
    });
  }

  getAllShifts() {
    this.data.getAllShifts(this.currentPage).subscribe((res: any) => {
      this.appointments = res.data;
      this.shiftPagination = res;
      console.log('this.appointments', this.appointments);  
      this.total = res.total;
      this.perPage = res.per_page;
      this.currentPage = res.current_page;
      this.dataSource.data = this.appointments;
    });
  }

}
