import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})
export class AdminSidebarComponent implements OnInit {

  userLocal: any = {};
  userRole: any;
  mode: any;
  constructor() { }

  ngOnInit(): void {

    this.mode = environment.mode;

    this.userLocal = JSON.parse(localStorage.getItem('user') || '{}');

    this.userRole = this.userLocal.role;

    console.log('userRole', this.userRole);
  }

}
