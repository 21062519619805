export const environment = {
  production: false,
  apiURL: 'https://api.citas.everybind.com/api',
  storageURL: 'https://api.citas.everybind.com/storage/',
  logotipo: 'https://api.citas.everybind.com/storage/logos/1726750943_logo_palmadelrio.png', // logotipo es el logotipo que aparece en la pantalla de inicio
  logotipo2: 'https://api.citas.everybind.com/storage/banners/1727193045_logo_azul.png', // logotipo2 es el logotipo que aparece en el monitor de turnos
  horizontal: true, // configura si el monitor de turnos es horizontal y vertical
  primaryColor: '#00309a', // color principal
  primaryColorVariation: '#00309a', // color de variación del primaryColor
  disableAppointment: false, // activa o desactiva la opción de cita previa
  mode: 1, // 1 para modo cita previa + turnos | modo 2 para solo turnos
  pdfPrint: true, // configura si se quiere imprimir el ticket en pdf o en impresión normal
  cajero: true, // configura si está integrado en un cajero PAUSE o no
  marketing: false, // activa o desactiva la opción de marketing y banners
  timeToReload: 15000, // tiempo de recarga una vez finaliza la solicitud de turno para volver a inicio
};
