import {Component, ViewChild, OnInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/services/services';

export interface dataUser {
  assignedAppointmentType_id: number;
  created_at: string;
  email: string;
  employeeDNI: string;
  employeeNumber: string;
  firstName: string;
  id: number;
  lastName: string;
}


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})

export class UserListComponent implements OnInit {
  page = {
    title: 'Lista de usuarios',
    subtitle: 'Aquí puedes ver la lista de usuarios registrados en el sistema'
  }

  employees: dataUser[] = [];

  displayedColumns: string[] = [ 'id', 'firstName', 'lastName', 'email', 'employeeDNI', 'employeeNumber','actions'];
  dataSource = new MatTableDataSource(this.employees);

  constructor(private data: DataService) { 
  }


  ngOnInit(): void {
    this.getEmployees()
  }

  getEmployees() {
    this.data.getEmployees().subscribe((res: any) => {
      this.employees = res;
      console.log(this.employees);
      this.dataSource.data = this.employees;
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}

