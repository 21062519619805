import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  types: any;
  res: any;
  logotipo: any;

  constructor(
    private router: Router,
    private data: DataService
  ) { }

  ngOnInit(): void {
    this.getShiftTypes();
    this.logotipo = environment.logotipo;
  }

  getShiftTypes() {
    console.log('Get shift types');
    this.data.getShiftTypes().subscribe((res: any) => {
      console.log(res);

      // revisa si el horario de start_time :  "08:30:00" y end_time : "14:00:00" es igual a la hora actual y agrega un valor que sea active: true o false

      res.forEach((shift: any) => {
        const start_time = new Date();
        start_time.setHours(parseInt(shift.start_time.split(':')[0]));
        start_time.setMinutes(parseInt(shift.start_time.split(':')[1]));
        start_time.setSeconds(parseInt(shift.start_time.split(':')[2]));

        const end_time = new Date();
        end_time.setHours(parseInt(shift.end_time.split(':')[0]));
        end_time.setMinutes(parseInt(shift.end_time.split(':')[1]));
        end_time.setSeconds(parseInt(shift.end_time.split(':')[2]));

        const now = new Date();

        shift.active = now >= start_time && now <= end_time;
      });

      this.types = res;
    });
  }

  nextStep(id: any): void {
    console.log('Next step', id);

    this.data.createShift({ shiftType_id: id }).subscribe(
      (res: any) => {
        console.log(res);
        this.res = res;
        this.router.navigate(['/shift/success'], { state: { res: this.res } });
      },
      (error: any) => {
        console.error(error);
        this.router.navigate(['/shift/error']);
      }
    );
  }
}
