import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-options-edit',
  templateUrl: './options-edit.component.html',
  styleUrls: ['./options-edit.component.css']
})
export class OptionsEditComponent implements OnInit {

  page: any = {
    title: 'Editar opción',
    subtitle: 'Aquí puedes editar una opción'
  }

  userForm: FormGroup;
  id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private toastr: ToastrService
    ) {

      this.userForm = this.formBuilder.group({
        name_options: [''],
        status: [''],
        options_type: ['']
      });

      // Disable the form controls if they exist
      const nameOptionsControl = this.userForm.get('name_options');
      const optionsTypeControl = this.userForm.get('options_type');
      
      if (nameOptionsControl) {
        nameOptionsControl.disable();
      }
      
      if (optionsTypeControl) {
        optionsTypeControl.disable();
      }
    }

  ngOnInit(): void {
    // obtener id de la pagina a traves del params del Router
    this.route.params.subscribe(params => {
      this.id = params['id']; // obtiene la ID desde los parámetros del router
    });
    this.data.getOptionsDetails(this.id).subscribe((data: any) => {
      console.log('data', data)
      this.userForm.patchValue(data);
    });

  }

  onSubmit() {
    if(confirm('¿Estás seguro de que quieres actualizar esta opción?')) {
      this.data.updateOption(this.userForm.value, this.id).subscribe(() => {
        this.router.navigate(['/admin/options']);
        this.toastr.success('Opción actualizada con éxito', 'Opción actualizada');
      });
    }
  }
}
