import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

// import { get } from 'http';



@Injectable({
    providedIn:'root'
})

export class DataService {
    totalPosts = null;
    pages = 0;
    token = localStorage.getItem('token');
    idUser = localStorage.getItem('user_id');
    logguedUser: any = localStorage.getItem('user')

    constructor(private http: HttpClient, private toastr: ToastrService, private router: Router) {

    }


    /* LOGIN AND REGISTER SERVICES */

    login(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };
        const body = {
            "email": data.email,
            "password": data.password
        }

        let response: any = [];

        this.http.post(environment.apiURL + '/auth/login', body, httpOptions).subscribe((res: any) => {
            // en caso de que el usuario sea correcto, se guarda el token en el localstorage
            if (res.token) {
                localStorage.setItem('token', res.token);
                localStorage.setItem('user', JSON.stringify(res.employee));
                localStorage.setItem('user_id', res.employee.id);
                localStorage.setItem('user_name', res.employee.firstName + ' ' + res.employee.lastName);
                this.token = res.token;
                this.idUser = res.employee.id;
            }
            // en caso de error se guarda el error en la variable response
            response = res;

            // si el usuario es correcto se redirige a la pagina de admin
            this.toastr.success(response.message);
            this.router.navigate(['/admin/dashboard']);

        }, (error) => {
            this.toastr.error('Usuario inválido', error.error.message);
        });
    
    }

    register(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };
        const body = {
            "name": data.name,
            "email": data.email,
            "password": data.password
        }
        return this.http.post(environment.apiURL + '/auth/register', body, httpOptions);
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_name');
        this.token = '';
        this.router.navigate(['/admin/login']);
        this.toastr.success('Sesión cerrada');
    }

    getLogguedUser() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/employees/' + this.idUser, httpOptions);
    }

    /* GET ALL SERVICES */

    getAppointments() {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/appointments', httpOptions);
    }

    getShift() {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/shift', httpOptions);
    }
    
    getShiftTypes() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/types', httpOptions);
    }

//     GET  /shifts/all
// GET  /api/appointments/all
// GET  /shifts/state/{stateId}
  
getAllShifts(page: any) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + this.token
        })
    };
    return this.http.get(environment.apiURL + '/shifts/all?page=' + page, httpOptions);
}

getAllAppointments(page: any) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + this.token
        })
    };
    return this.http.get(environment.apiURL + '/appointments/all?page=' + page, httpOptions);
}

getShiftsByState(stateId: any) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + this.token
        })
    };
    return this.http.get(environment.apiURL + '/shifts/state/' + stateId, httpOptions);
}
                

    getAppointmentByType(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/appointments/type/' + id, httpOptions);
    }

    getEmployees() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/employees', httpOptions);
    }

    getStates() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/states', httpOptions);
    }

    getOverthecounter() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/overthecounter', httpOptions);
    }

    getOptions() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/options', httpOptions);
    }

    getBanner() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/banners', httpOptions);
    }

    getAppointmentDetails(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/appointments/' + id, httpOptions);
    }

    getShiftDetails(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/shift/' + id, httpOptions);
    }

    getOverthecounterDetails(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/overthecounter/' + id, httpOptions);
    }

    getTypesDetails(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/types/' + id, httpOptions);
    }

    getEmployeesDetails(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/employees/' + id, httpOptions);
    }

    getStatesDetails(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/states/' + id, httpOptions);
    }

    getOptionsDetails(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/options/' + id, httpOptions);
    }

    getBannerDetails(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.get(environment.apiURL + '/banners/' + id, httpOptions);
    }


    getMyShifts(status: any) {
        const logguedUser: any = localStorage.getItem('user');
        const logguedEmployee = JSON.parse(logguedUser);

        // llamo a los tipos de citas asignados al empleado logueado
        const body = {
            "tipo_cita_ids": logguedEmployee.assignedAppointmentType_id,
            "status_id": status
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })

        };
        return this.http.post(environment.apiURL + '/shift/by-types', body, httpOptions);
      }

    /* DELETE ALL SERVICES */

    deleteAppointment(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.delete(environment.apiURL + '/appointments/' + id, httpOptions);
    }
    deleteShift(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.delete(environment.apiURL + '/shift/' + id, httpOptions);
    }

    // delete all services
    deleteShiftType(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.delete(environment.apiURL + '/types/' + id, httpOptions);
    }

    deleteEmployee(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.delete(environment.apiURL + '/employees/' + id, httpOptions);
    }

    deleteState(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.delete(environment.apiURL + '/states/' + id, httpOptions);
    }
    
    deleteOverthecounter(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.delete(environment.apiURL + '/overthecounter/' + id, httpOptions);
    }

    deleteOption(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.delete(environment.apiURL + '/options/' + id, httpOptions);
    }

    deleteBanner(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };
        return this.http.delete(environment.apiURL + '/banners/' + id, httpOptions);
    }

    /* PUT ALL SERVICES */

    updateAppointment(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };

        const body = data;
        return this.http.put(environment.apiURL + '/appointments/' + id, body, httpOptions);
    }

    updateShift(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };

        const body = data;
        return this.http.put(environment.apiURL + '/shift/' + id, body, httpOptions);
    }

    updateShiftType(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };

        const body = {
            "name": data.name,
            "description": data.description,
            "start_time": data.start_time,
            "end_time": data.end_time,
            "simultaneous_appointments": data.simultaneous_appointments,
            "time_interval": data.time_interval
        }
        return this.http.put(environment.apiURL + '/types/' + id, body, httpOptions);
    }

    updateEmployee(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };

        
        const body = {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "employeeNumber": data.employeeNumber,
            "employeeDNI": data.employeeDNI,
            "email": data.email,
            "password": data.password,
            "role": data.role,
            "assignedAppointmentType_id": '[' + data.assignedAppointmentType_id.join(',') + ']', // array to string
            "overthecounter_id": data.overthecounter_id
        }

        const userLocal = JSON.parse(this.logguedUser);
        console.log('body', body);
        console.log('userLocal', userLocal);
        
        return this.http.put(environment.apiURL + '/employees/' + id, body, httpOptions);
    }

    updateEmployeeShift(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };

        return this.http.put(environment.apiURL + '/employees/' + id, data, httpOptions);
    }


    updateState(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };

        const body = {
            "name": data.name,
            "description": data.description
        }
        return this.http.put(environment.apiURL + '/states/' + id, body, httpOptions);
    }

    updateOverthecounter(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };

        const body = data;
        return this.http.put(environment.apiURL + '/overthecounter/' + id, body, httpOptions);
    }

    updateOption(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };

        const body = data;
        return this.http.put(environment.apiURL + '/options/' + id, body, httpOptions);
    }

    updateBanner(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token
            })
        };

        const body = data;
        return this.http.put(environment.apiURL + '/banners/' + id, body, httpOptions);
    }


    /* POST ALL SERVICES */

    createShift(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };

        const body = {
            "shiftType_id": data.shiftType_id
        }
        return this.http.post(environment.apiURL + '/shift', body, httpOptions);
    }

    createShiftType(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };
        return this.http.post(environment.apiURL + '/types', data, httpOptions);
    }


    createEmployee(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };
        const body = {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "employeeNumber": data.employeeNumber,
            "employeeDNI": data.employeeDNI,
            "email": data.email,
            "password": data.password,
            "assignedAppointmentType_id": '[' + data.assignedAppointmentType_id.join(',') + ']', // array to string
            "overthecounter_id": data.overthecounter_id
        }

        return this.http.post(environment.apiURL + '/employees', body, httpOptions);
    }

    createState(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };

        const body = {
            "name": data.name,
            "description": data.description
        }
        return this.http.post(environment.apiURL + '/states', body, httpOptions);
    }

    createOverthecounter(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };

        const body = {
            "name": data.name,
            "description": data.description
        }
        return this.http.post(environment.apiURL + '/overthecounter', body, httpOptions);
    }

    createOption(data: any, imagen: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };

        const formData = new FormData();
        formData.append('name_options', data.name_options);
        formData.append('options_type', data.option_type);
        if (data.logo) {
            formData.append('logo', imagen);
        }
        formData.append('status', data.status || '1');

        return this.http.post(environment.apiURL + '/options', formData, httpOptions);
    }

    createBanner(data: any, imagen: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.token,
            })
        };
        // convierte los true o false en 1 o 0 de monitor y solicitud
        data.monitor = data.monitor ? 1 : 0;
        data.solicitud = data.solicitud ? 1 : 0;

        const formData = new FormData();

        formData.append('imagen', imagen);
        formData.append('monitor', data.monitor);
        formData.append('solicitud', data.solicitud);
        
        return this.http.post(environment.apiURL + '/banners', formData, httpOptions);
    }


    // LISTA DE SERVICIOS DEL CALENDARIO DE CITAS

    // servicio que recoge los días del calendario que no hay citas
    getCalendarDays(type_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };

        // obtiene el día de hoy en formato 2024-09-01
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const todayDate = yyyy + '-' + mm + '-' + dd;

        // obtiene el día dentro de 1 año en formato 2025-09-01
        const todayPlusOneYear = new Date();
        todayPlusOneYear.setFullYear(todayPlusOneYear.getFullYear() + 1);
        const todayPlusOneYearDate = todayPlusOneYear.getFullYear() + '-' + String(todayPlusOneYear.getMonth() + 1).padStart(2, '0') + '-' + String(todayPlusOneYear.getDate()).padStart(2, '0');


        const body = {
            "start_date": todayDate,
            "end_date": todayPlusOneYearDate,
            "types": type_id
        }
        return this.http.post(environment.apiURL + '/calendars/filter', body, httpOptions);
    }

    getHoursByDay(day: any, type_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };

        // configura para que day.month y day.day siempre tengan 2 dígitos
        day.month = String(day.month).padStart(2, '0');
        day.day = String(day.day).padStart(2, '0');

        // crear el día en formato 2024-09-01 00:00:00
        const dayDateFormatted = day.year + '-' + day.month + '-' + day.day + ' 00:00:00';
        const dayEndDateFormatted = day.year + '-' + day.month + '-' + day.day + ' 23:59:59';
        

        const body = {
            "start_datetime": dayDateFormatted,
            "end_datetime": dayEndDateFormatted,
            "tipo_cita_ids": [type_id]
        }

        return this.http.post(environment.apiURL + '/appointments/by-types-datetime', body, httpOptions);
    }

    // POST  /api/appointments/availability
    // servicio que recoge las horas disponibles para un día y un tipo de cita
    getAvailableHoursByDay(day: any, type_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };

        const body = {
            "date": day,
            "tipo_cita_id": type_id
        }
        return this.http.post(environment.apiURL + '/appointments/availability', body, httpOptions);
    }

    // POST CREAR CITA
    createAppointment(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };

        const body = {
            // 'appointmentIdentifier', 'firstName', 'lastName', 'dni', 'phone', 'email', 'appointmentType_id', 'employee_id', 'status_id', 'datetime', 'validate', 'shift_id'
            "firstName": data.firstName,
            "lastName": data.lastName,
            "dni": data.dni,
            "phone": data.phone,
            "email": data.email,
            "appointmentType_id": data.appointmentType_id,
            "datetime": data.datetime,
        }
        return this.http.post(environment.apiURL + '/appointments', body, httpOptions);
    }


    // DÍA DE NO DISPONIBILIDAD EN EL CALENDARIO

    getNoAvailabilityDay() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };
        return this.http.get(environment.apiURL + '/calendars', httpOptions);
    }

    getNoAvailabilityDayDetails(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };
        return this.http.get(environment.apiURL + '/calendars/' + id, httpOptions);
    }

    createNoAvailabilityDay(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };

        const body = {
            "day": data.day, 
            "type": '[' + data.type.join(',') + ']'
        }
        return this.http.post(environment.apiURL + '/calendars', body, httpOptions);
    }


    updateNoAvailabilityDay(data: any, id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };
        
        const body = {
            "day": data.day,
            "type": '[' + data.type.join(',') + ']'
        }
        return this.http.put(environment.apiURL + '/calendars/' + id, body, httpOptions);
    }

    
}
