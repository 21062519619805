import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/services';
import { NgbDatepickerI18n, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWidth } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

// Define the translations
const I18N_VALUES: any = {
  es: {
    weekdays: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  }


  // otros idiomas que desees soportar
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'es';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    const language = this._i18n.language;
    const weekdays = I18N_VALUES[language]?.weekdays;
    if (weekdays) {
      return weekdays[weekday - 1];
    }
    return ''; // Valor por defecto si no se encuentran los datos
  }

  getMonthShortName(month: number): string {
    const language = this._i18n.language;
    const months = I18N_VALUES[language]?.months;
    if (months) {
      return months[month - 1];
    }
    return ''; // Valor por defecto si no se encuentran los datos
  }

  getMonthFullName(month: number): string {
    const language = this._i18n.language;
    const months = I18N_VALUES[language]?.months;
    if (months) {
      return months[month - 1];
    }
    return ''; // Valor por defecto si no se encuentran los datos
  }

  getDayAriaLabel(date: { year: number; month: number; day: number }): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number): string {
    return this.getWeekdayShortName(weekday);
  }
}

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class CreateAppointmentComponent implements OnInit {

  personalDataForm!: FormGroup;
  
  resume: any;

  steps: any = [
    { title: 'Seleccionar turno', id: 1 },
    { title: 'Datos personales', id: 2 },
    { title: 'Resumen', id: 3 }
  ];

  loading: boolean = true;

  currentStep: number = 1;

  types: any;
  res: any;

  date: any;

  category: any;

  model: NgbDateStruct | null = null;
  citasDisponibles: Date[] = [
    
  ];

  hours: any = [];

  hourSelected: any;

  selectedDate: any;

  notAvaibleDates: any = [];

  logotipo: any;

  logotipo2: any;


  constructor(
   private router: Router,
   private data: DataService,
   private calendar: NgbCalendar,
   private toast: ToastrService,
   private fb: FormBuilder
  ) { 
  }

  ngOnInit(): void {
    this.getAppointmentTypes();
    this.logotipo = environment.logotipo;
    

    

    this.personalDataForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      dni: ['', [this.dniValidator]]
    });

    // Recuperar los datos del estado de navegación
    this.category = history.state.res;
    console.log('category', this.category);

    this.getDatesCalendar();

  }

    // Custom validator for DNI or NIE
    dniValidator(control: any): { [key: string]: boolean } | null {
      const dniNiePattern = /^[XYZ]?\d{5,8}[A-Za-z]$/;
      if (control.value && !dniNiePattern.test(control.value)) {
        return { 'dniInvalid': true };
      }
      return null;
    }


  onSubmit(): void {
    if (this.personalDataForm?.valid) {
      console.log('Formulario enviado', this.personalDataForm.value);

      const appointmentDate = new Date(
      this.model!.year,
      this.model!.month - 1,
      this.model!.day,
      this.hourSelected.hour.split(':')[0],
      this.hourSelected.hour.split(':')[1]
      );

      const formattedDate = appointmentDate.getFullYear() + '-' +
      ('0' + (appointmentDate.getMonth() + 1)).slice(-2) + '-' +
      ('0' + appointmentDate.getDate()).slice(-2) + ' ' +
      ('0' + appointmentDate.getHours()).slice(-2) + ':' +
      ('0' + appointmentDate.getMinutes()).slice(-2) + ':' +
      ('0' + appointmentDate.getSeconds()).slice(-2);

      const appointmentData = {
      firstName: this.personalDataForm.value.firstName,
      lastName: this.personalDataForm.value.lastName,
      dni: this.personalDataForm.value.dni,
      phone: this.personalDataForm.value.phone,
      email: this.personalDataForm.value.email,
      appointmentType_id: this.category.id,
      datetime: formattedDate
      };
      this.loading = true;

      this.data.createAppointment(appointmentData).subscribe((response: any) => {
      this.loading = false;
      console.log('Cita creada:', response);
      this.resume = response.cita;

      // timeout para volver a inicio de 30 segundos
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 30000);

      }, (error: any) => {
      console.error('Error al crear la cita:', error);
      });

      this.nextStep();
    } else {
      console.log('Formulario no válido');
      this.toast.error('Por favor, rellene todos los campos obligatorios');

      // Marcar los campos no válidos en rojo
      Object.keys(this.personalDataForm.controls).forEach(field => {
      const control = this.personalDataForm.get(field);
      if (control && control.invalid) {
        control.markAsTouched({ onlySelf: true });
      }
      });
    }
  }

  getAppointmentTypes() {
    this.loading = true;
    this.data.getShiftTypes().subscribe((res: any) => {
      this.loading = false;
      this.types = res;
    });
  }

   // Método para verificar si un día tiene cita
   tieneCita(fecha: NgbDateStruct): boolean {
    const date = new Date(fecha.year, fecha.month - 1, fecha.day);
    return this.citasDisponibles.some(cita =>
      cita.getFullYear() === date.getFullYear() &&
      cita.getMonth() === date.getMonth() &&
      cita.getDate() === date.getDate()
    );
  }

  onDateSelect(date: NgbDateStruct) {
    if (this.tieneCita(date)) {
      console.log('Cita seleccionada:', date);
      this.date = date;

      // date string format 2024-09-20 teniendo en cuenta que los digitos deben ser minimo 2 digitos
      const dateStr = date.year + '-' + ('0' + date.month).slice(-2) + '-' + ('0' + date.day).slice(-2);

      this.loading = true;

      this.data.getAvailableHoursByDay(dateStr, this.category.id).subscribe((res: any) => {
        this.loading = false;
        console.log('this.date', this.date);
        console.log('Horas disponibles:', res);
        // elimina las horas que ya han pasado si la cita es para el día de hoy
        const today = new Date();
        const selectedDate = new Date(this.date.year, this.date.month - 1, this.date.day);
        
        if (selectedDate.toDateString() === today.toDateString()) {
          const now = today.getHours() + ':' + ('0' + today.getMinutes()).slice(-2);
          this.hours = res.filter((hour: any) => {
            return hour.hour >= now;
          });
        } else {
          this.hours = res;
        }
        
        
      });

    }
  }

  selectHour(hour: any) {
    this.hourSelected = hour;
    this.selectedDate = this.model!.day + '/' + (this.model!.month) + '/' +  this.model!.year + ' a las ' + hour.hour;
    this.nextStep();
  }

  nextStep() {
    this.currentStep++;
  }

  previousStep() {
    this.currentStep--;
  }

  getDatesCalendar() {
    this.loading = true;
    this.data.getCalendarDays([this.category.id]).subscribe((res: any) => {
      this.loading = false;
      console.log(res);
      this.notAvaibleDates = res;
      // convierte los días no disponibles en un array de fechas disponibles desde hoy hasta dentro de un año, en citasDisponibles teniendo en cuenta que las fechas vienen como string 2024-09-21 en res.day 
      const today = new Date();
      const oneYearFromNow = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

      const unavailableDates = res.map((item: any) => {
        const date = item.day.split('-');
        return new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]));
      });

      this.citasDisponibles = [];
      for (let d = new Date(today); d <= oneYearFromNow; d.setDate(d.getDate() + 1)) {
        if (!unavailableDates.some((unavailableDate:any) =>
          unavailableDate.getFullYear() === d.getFullYear() &&
          unavailableDate.getMonth() === d.getMonth() &&
          unavailableDate.getDate() === d.getDate()
        )) {
          this.citasDisponibles.push(new Date(d));
        }
      }

    });
  }

  
}
