<form>
    <div>
        <label for="optionName">Nombre de la Opción:</label>
        <input type="text" id="optionName" name="optionName" required>
    </div>
    <div>
        <label for="optionType">Tipo de la Opción:</label>
        <input type="text" id="optionType" name="optionType" required>
    </div>
    <div>
        <label for="optionImage">Imagen de la Opción:</label>
        <input accept=".jpg, .png, .jpeg, .webp" type="file" id="file" (change)="onFileSelected($event)">
    </div>
    <button type="submit" (click)="createOption()">Añadir Opción</button>
</form>
