<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
</div>
<div class="internal-content">
  <div class="filter-box">
    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Escribe para filtrar" #input>
    </mat-form-field>  
    <mat-form-field>
      <mat-label>Fecha Desde</mat-label>
      <input matInput [matDatepicker]="startPicker" placeholder="Selecciona fecha de inicio" (dateChange)="applyDateFilter($event, 'start')" #startDate>
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fecha Hasta</mat-label>
      <input matInput [matDatepicker]="endPicker" placeholder="Selecciona fecha de fin" (dateChange)="applyDateFilter($event, 'end')" #endDate>
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Categoría</mat-label>
      <mat-select (selectionChange)="applyCategoryFilter($event)" #categorySelect>
        <mat-option *ngFor="let category of categories" [value]="category.id">{{category.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="button-group">
      <button class="btn btn-primary" mat-raised-button (click)="applyTodayFilter()">Hoy</button>
      <button class="btn btn-primary" mat-raised-button (click)="applyTomorrowFilter()">Mañana</button>
      <button class="btn btn-secondary" mat-raised-button (click)="clearFilters()">Limpiar</button>
      <button class="btn btn-tertiary" mat-raised-button (click)="seeAll()">Ver todas las citas</button>
    </div>
  </div>

      
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- ID Column -->
        <!-- <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container> -->
            
        <!-- Appointment Identifier Column -->
        <ng-container matColumnDef="appointmentIdentifier">
          <th mat-header-cell *matHeaderCellDef> Identificador de Cita </th>
          <td mat-cell *matCellDef="let element"> {{element.appointmentIdentifier}} </td>
        </ng-container>
            
        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>
            
        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef> Apellido </th>
          <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
        </ng-container>
            
        <!-- DNI Column -->
        <ng-container matColumnDef="dni">
          <th mat-header-cell *matHeaderCellDef> DNI </th>
          <td mat-cell *matCellDef="let element"> {{element.dni}} </td>
        </ng-container>
            
        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Teléfono </th>
          <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
        </ng-container>
            
        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Correo Electrónico </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
            
        <!-- Datetime Column -->
        <ng-container matColumnDef="datetime">
          <th mat-header-cell *matHeaderCellDef> Fecha y Hora </th>
            <td mat-cell *matCellDef="let element"> {{element.datetime | date:'dd/MM/yyyy HH:mm'}}h </td>
        </ng-container>
            
        <!-- Type Name Column -->
        <ng-container matColumnDef="typeName">
          <th mat-header-cell *matHeaderCellDef> Categoría </th>
          <td mat-cell *matCellDef="let element"> {{element.type.name}} </td>
        </ng-container>

        <!-- Type Name Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Validado </th>
          <td mat-cell *matCellDef="let element"> <div class="state_id" [class.valid]="element?.status_id == 10"></div> </td>
        </ng-container>
            
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let element">
            <!-- Aquí puedes poner botones de acción, como editar o eliminar -->
            <button class="btn btn-secondary" (click)="edit(element)">Editar</button>
            <!--
            <button mat-button (click)="delete(element)">Delete</button> -->
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <!-- Paginador -->
      <mat-paginator [length]="total" [pageSize]="perPage" (page)="onPageChange($event)">
      </mat-paginator>

      <div class="loading loadpage" *ngIf="loading">
        <div class="loading-content">
            <div class="loading-icon">
                <img src="assets/oval.svg" alt="Cargando">
            </div>
        </div>
    </div>
</div>