<div class="internal-subheader">
    <div class="container">
        <h2>{{page.title}}</h2>
        <h5>{{page.subtitle}}</h5>
    </div>
    <div class="button-group">
        <a class="btn btn-secondary" [routerLink]="['/admin/users/add']">Crear nuevo</a>
    </div>
</div>
<div class="internal-content">
    <mat-form-field>
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Escribe para filtrar" #input>
      </mat-form-field>
      
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

        <!-- firstName Column -->
        <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>
        
        <!-- lastName Column -->
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef> Apellidos </th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
          </ng-container>
      
        <!-- email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
      
        <!-- employeeDNI Column -->
        <ng-container matColumnDef="employeeDNI">
          <th mat-header-cell *matHeaderCellDef>DNI </th>
          <td mat-cell *matCellDef="let element"> {{element.employeeDNI}} </td>
        </ng-container>
      
        <!-- employeeNumber Column -->
        <ng-container matColumnDef="employeeNumber">
            <th mat-header-cell *matHeaderCellDef> Identificación </th>
            <td mat-cell *matCellDef="let element"> {{element.employeeNumber}} </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element"> 
                <a class="btn btn-secondary btn-normal" [routerLink]="['/admin/users/edit/'+ element.id]">Editar</a>
            </td>
          </ng-container>
      
      
      
        <!-- Agrega más columnas según sea necesario -->
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
        <!-- Fila que se muestra cuando no hay datos coincidentes -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            No hay datos que coincidan con el filtro "{{input.value}}"
            </td>
        </tr>
      </table>
</div>