import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {

  page: any = {
    title: 'Crear usuario',
    subtitle: 'Aquí puedes crear un nuevo usuario'
  }

  userForm: FormGroup;
  appointmentTypes: any;
  overthecounters: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private data: DataService,
    private toastr: ToastrService
    ) {

      this.userForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        employeeNumber: [''],
        employeeDNI: [''],
        email: ['', Validators.required],
        password: ['', Validators.required],
        assignedAppointmentType_id: ['', Validators.required],
        overthecounter_id: ['', Validators.required],
        // now_appointment: ['', Validators.required]
      });

     }

  ngOnInit(): void {
    this.getAppointmentTypes();
    this.getOverTheCounter();
  }

  getAppointmentTypes() {
    this.data.getShiftTypes().subscribe((data: any) => {
      this.appointmentTypes = data;
    });
  }

  getOverTheCounter() {
    this.data.getOverthecounter().subscribe((data: any) => {
      this.overthecounters = data;
      console.log(this.overthecounters);
    });
  }

  onSubmit() {
    this.data.createEmployee(this.userForm.value).subscribe(() => {
      this.router.navigate(['/admin/users']);
      this.toastr.success('Usuario creado con éxito', 'Usuario creado');
    }, (error) => {
      this.toastr.error('Error al crear el usuario', 'Error');
    }
    );
  }

}
