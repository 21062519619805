import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  page: any = {
    title: 'Escritorio',
    subtitle: 'Bienvenido a tu panel de control de administrador de citas'
  };

  loggedUser: any;
  categories: any[] = [];
  allCategories: any[] = [];
  rightNowAppointment: any;
  myAppointments: any[] = [];
  appointments: any[] = [];

  constructor(private data: DataService) { }

  ngOnInit(): void {

    this.getMyAppointments();
    this.getShift();

    this.data.getLogguedUser().subscribe((user: any) => {
      this.loggedUser = user;
      // Recoge la cita que tiene asignada en este momento el usuario logueado
      this.data.getShiftDetails(this.loggedUser.now_appointment).subscribe((res: any) => {
        this.rightNowAppointment = res;
        console.log('this.rightNowAppointment', this.rightNowAppointment);
      });
      // recoge las categorias de citas asignadas al usuario
      this.loggedUser.assignedAppointmentType_id.forEach((appointment: any) => {
        this.data.getTypesDetails(appointment).subscribe((res: any) => {
          this.categories.push(res);
        });
      });
    });

  }

  getMyAppointments() {
      // recoge las citas del usuario logueado para mostrarlas en la vista
      this.data.getMyShifts(1).subscribe((res: any) => {
        this.myAppointments = res;
      });
    }

    getShift() {
      this.data.getShift().subscribe((res: any) => {
        // filtra por status id 1 (pendiente)
        this.appointments = res.filter((appointment: any) => appointment.status_id == 1);
      });
    }

  

}
